import { Button, Toggle } from "@fscrypto/ui";
import { SunMoon } from "lucide-react";

export const DarkModeField = ({ theme = "light", setTheme }: { theme?: Theme; setTheme: (theme: Theme) => void }) => {
  return (
    <Button size="md" variant="transparent" className="w-full justify-start px-2 text-sm" asChild>
      <div>
        <SunMoon className="mr-3 h-3.5 w-3.5" />
        Dark Mode
        <div className="flex flex-1 justify-end">
          <Toggle
            label="Enable dark mode"
            enabled={theme === "dark"}
            setEnabled={() => setTheme(theme === "dark" ? "light" : "dark")}
          />
        </div>
      </div>
    </Button>
  );
};

type Theme = "light" | "dark";
