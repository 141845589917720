import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";
import { DashboardView } from "@fscrypto/domain/dashboard";

export const useTabState = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const view = dashboardView === "published" ? "published" : "draft";
  const tabStateId = `${view}-${cellId}`;
  const entity = useEntity(dashboardFactory, dashboardId);
  const tabStateStore = entity?.getTabStateStore(tabStateId);
  const tabState = useOptionalStore(tabStateStore);
  return {
    activeTabId: tabState?.activeTabId ?? null,
    setActiveTabId: (tabId: string | null) => entity?.setTabState(tabStateId, tabId),
  };
};
