import { CSSProperties, lazy, Suspense } from "react";
import dynamicIconImports from "lucide-react/dynamicIconImports";

type IconProps = {
  name: string;
  className: string;
  style?: CSSProperties;
};

export const DynamicIcon = ({ name, ...props }: IconProps) => {
  if (!(name in dynamicIconImports)) {
    return null;
  }

  const LucideIcon = lazy(dynamicIconImports[name as keyof typeof dynamicIconImports]);
  const fallback = <span className={props.className} />;

  return (
    <Suspense fallback={fallback}>
      <LucideIcon {...props} />
    </Suspense>
  );
};
