import { Button, ColorPicker, Label, Popover, Text } from "@fscrypto/ui";
import clsx from "clsx";
import { DynamicIcon } from "~/shared/components/dynamic-icon";
import { useState } from "react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import { Command, CommandEmpty, CommandInput, CommandItem, CommandList } from "@fscrypto/ui/command";
import { XIcon } from "lucide-react";

export const IconField = ({
  icon,
  onIconChange,
  iconColor,
  onIconColorChange,
}: {
  icon: string;
  onIconChange: (icon: string) => void;
  iconColor: string;
  onIconColorChange: (iconColor: string) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-4 px-2">
      <Text size="xs" color="muted">
        You can select any icon from the lucide library. Documentation can be found{" "}
        <a href="https://lucide.dev/icons" target="_blank" rel="noreferrer" className="text-primary">
          here
        </a>
      </Text>
      {icon && (
        <div className="flex w-full justify-center py-2">
          <DynamicIcon name={icon} className="size-8" style={{ color: iconColor }} />
        </div>
      )}
      <Label className="text-muted-foreground">Select Name</Label>
      <IconSelector onIconChange={onIconChange} icon={icon} />
      <ColorPicker
        onChange={(color) => {
          onIconColorChange(color);
        }}
        value={iconColor ?? "#000"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text color="muted">Icon Color</Text>
            <Swatch bgColor={color} />
          </div>
        )}
      />
    </div>
  );
};

export const Swatch = ({ bgColor }: { bgColor: string }) => (
  <div className={clsx("size-8 rounded-lg border shadow")} style={{ background: bgColor }}></div>
);

export const IconSelector = ({ icon, onIconChange }: { icon?: string; onIconChange: (icon: string) => void }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const filteredIcons =
    inputValue.length >= 3
      ? Object.keys(dynamicIconImports).filter((name) => name.toLowerCase().includes(inputValue.toLowerCase()))
      : [];
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        {icon ? (
          <Button variant="outline" className="w-full justify-between">
            <DynamicIcon name={icon} className="size-6 text-muted-foreground mr-2" />
            {icon}
            <XIcon
              className="h-4 w-4"
              onClick={(e) => {
                e.stopPropagation();
                onIconChange("");
              }}
            />
          </Button>
        ) : (
          <Button
            variant="ghost"
            role="combobox"
            aria-expanded={open}
            className="text-muted-foreground/50 w-full justify-start border border-dashed"
          >
            Find Icon Name
          </Button>
        )}
      </Popover.Trigger>
      <Popover.Content className="max-h-60 w-64 overflow-y-auto" sideOffset={-36} side="bottom">
        <Command shouldFilter={true}>
          <CommandInput
            placeholder="Search Icons..."
            className="h-9 w-full"
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            {filteredIcons.length > 0 ? (
              filteredIcons.map((name) => (
                <CommandItem key={name} value={name} onSelect={onIconChange}>
                  <DynamicIcon name={name} className="size-6 text-muted-foreground mr-2" />
                  <Text>{name}</Text>
                </CommandItem>
              ))
            ) : (
              <CommandEmpty>No Icons found</CommandEmpty>
            )}
          </CommandList>
        </Command>
      </Popover.Content>
    </Popover.Root>
  );
};
