import { CellInfo, DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";
import { useDashboard } from "./useDashboard";

export const useDashboardCellLayout = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const { entity } = useDashboard(dashboardId);
  const layout = useStoreWithDynamicSelector(db?.store, (s) =>
    dashboardView === "published" ? s.publishedConfig?.layouts[cellId] : s.draftConfig.layouts[cellId],
  );
  const toggleLocked = () => {
    entity?.toggleCellLocked(cellId);
  };
  return { isLocked: layout?.static, toggleLocked };
};
