import { Field, Input, Label, Switch } from "@fscrypto/ui";
import { ReactNode } from "react";

export const TextInputField = ({
  label,
  value,
  onChange,
  onChangeShouldShow,
  disabled,
  startAddon,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  onChangeShouldShow?: (value: boolean) => void;
  startAddon?: ReactNode;
}) => {
  return (
    <Field>
      <div className="flex w-full items-center justify-between">
        <Label className="text-muted-foreground">{label}</Label>
        {onChangeShouldShow && <Switch checked={disabled} onCheckedChange={(on) => onChangeShouldShow(on)} />}
      </div>
      <Input value={value} onChange={(e) => onChange(e.target.value)} disabled={disabled} startAddon={startAddon} />
    </Field>
  );
};
