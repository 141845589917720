import { CellVariant, ContentByVariant, DashboardView, dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useCallback } from "react";
import { dashboardFactory } from "../state/dashboard";
export const useDashboardCellContent = <V extends CellVariant>(
  cellId: string,
  dashboardId: string,
  variant: V,
  dashboardView: DashboardView,
): [Partial<ContentByVariant<V>>, (c: Partial<ContentByVariant<V>>) => void] => {
  const db = useEntity(dashboardFactory, dashboardId);
  const content = useStoreWithDynamicSelector(db?.store, (s) =>
    dashboardView === "published" ? s.publishedConfig?.contents[cellId] : s.draftConfig.contents[cellId],
  );

  const setContent = useCallback(
    (cellContent: Partial<ContentByVariant<V>>) => {
      if (db) {
        const newDb = dashboardDomain.setCellContent<V>(db.store.get(), cellId, cellContent);
        db.update(newDb);
      }
    },
    [cellId, db],
  );

  if (!db || !content) {
    return [{}, () => {}];
  }

  if (variant === "base") {
    return [content as ContentByVariant<V>, setContent];
  }

  const isCellContent = dashboardDomain.isCellContentVariant(content, variant);
  if (!isCellContent) {
    const err = `Incorrect use of cell content variant: id ${content.id}, ${content.variant} vs ${variant}`;
    console.error(err);
    console.error("Content: ", content);
    throw new Error(err);
  }

  return [content, setContent];
};
