import { DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useActiveHoverId } from "../hooks/useActiveHoverId";
import { useDashboard } from "../hooks/useDashboard";
import { useDashboardCell } from "../hooks/useDashboardCell";
import { useDashboardEditor } from "../hooks/useDashboardEditor";

/**
 * Custom hook for managing active cell interactions and state
 *
 * @param {string} cellId - The ID of the current cell
 * @param {string} dashboardId - The ID of the dashboard
 * @param {DashboardView} dashboardView - The current view mode of the dashboard
 * @returns {Object} An object containing various properties and methods for cell interaction
 */
export const useActiveCellListener = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const activeHoverId = useActiveHoverId();
  const { entity } = useDashboard(dashboardId);
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  const { activeCellId, setActiveLayoutId, setActiveCellId, activeLayoutId } = useDashboardEditor({ dashboardId });

  const isHovered = cellId === activeHoverId;
  const dashboard = entity?.store.get();
  const isAncestorOfActive = dashboard && activeCellId && dashboardDomain.isAncestor(dashboard, activeCellId, cellId);
  const isActiveCell = cellId === activeCellId;
  const isActiveLayout = cellId === activeLayoutId;

  /**
   * Handles click events on the cell
   * @param {React.MouseEvent} e - The click event object
   */
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (cell.variant === "layout" || cell.variant === "tab-layout") {
      setActiveLayoutId(cellId, true);
    } else {
      setActiveLayoutId(cell.parentCellId || cellId, true);
    }
    setActiveCellId(cellId);
  };

  const showHoverMask = isHovered && !isActiveCell && !isAncestorOfActive;
  const showAncestorBorder = isAncestorOfActive && isHovered && dashboardView === "draft";

  return {
    isHovered,
    isAncestorOfActive,
    isActiveCell,
    isActiveLayout,
    handleClick,
    showHoverMask,
    showAncestorBorder,
    cell,
  };
};
