import { useEntity } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";

export const useDashboard = (id: string) => {
  const entity = useEntity(dashboardFactory, id);
  if (!entity) {
    return {} as const;
  }
  return {
    entity,
    updateLayouts: entity.updateLayouts.bind(entity),
    addDashboardCell: entity.addDashboardCell.bind(entity),
    addVisualizationCell: entity.addVisualizationCell.bind(entity),
    addParamsCell: entity.addParamsCell.bind(entity),
    addTabsCell: entity.addTabsCell.bind(entity),
    moveDashboardCell: entity.moveDashboardCell.bind(entity),
    removeDashboardCell: entity.removeDashboardCell.bind(entity),
    update: entity.update.bind(entity),
    // delete: entity.delete.bind(entity),
    get: entity.get.bind(entity),
    upgrade: entity.upgrade.bind(entity),
    toggleCellLocked: entity.toggleCellLocked.bind(entity),
  };
};
