import type { Comment as CommentType } from "@fscrypto/domain/comments";

import { Avatar, Button } from "@fscrypto/ui";
import { Card, CardContent, Input, Text } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { captureMessage } from "@sentry/remix";
import clsx from "clsx";
import { DateTime } from "luxon";
import { useState } from "react";
import { useCurrentUser } from "~/features/current-user";
import { usePublicProfile } from "~/state/entities/profile";
import { tracking } from "~/utils/tracking";
import { useComment } from "../state/useComments";

type FlaggingProps = {
  commentId: string;
};

const Flagging = ({ commentId }: FlaggingProps) => {
  const { flag, isFlaggedByCurrentUser } = useComment(commentId);
  const [hasFlagged, setHasFlagged] = useState(isFlaggedByCurrentUser);
  const [isGivingFlagReason, setIsGivingFlagReason] = useState(false);
  const [reasonForFlagging, setReasonForFlagging] = useState("");

  const handleSubmit = () => {
    flag(reasonForFlagging);
    setHasFlagged(true);
    setIsGivingFlagReason(false);
  };

  if (hasFlagged) {
    return (
      <Text weight={"light"} size={"xs"} className={"italic"}>
        flagged
      </Text>
    );
  }

  if (isGivingFlagReason) {
    return (
      <div className="flex w-full">
        <Input
          className="mr-2 flex-grow"
          name="reason"
          onChange={(e) => setReasonForFlagging(e.target.value)}
          placeholder="Reason for flagging"
          type="text"
        />
        <Button
          onClick={() => {
            handleSubmit();
          }}
          disabled={!reasonForFlagging}
          type="button"
        >
          Flag
        </Button>
      </div>
    );
  }

  const onFlagClick = () => {
    tracking("comments-flag-comment", "Dashboard Beta");
    setIsGivingFlagReason(true);
  };

  return (
    <Button variant="text" size="xs" onClick={onFlagClick}>
      flag
    </Button>
  );
};

export const Comment = ({ className, content, createdAt, id, profileId }: CommentType & { className?: string }) => {
  const creator = usePublicProfile(profileId);
  const { profile } = useCurrentUser();

  const createdAtDate = new Date(createdAt);
  const tsString = DateTime.fromMillis(createdAtDate.getTime()).toRelative();
  const isLoggedIn = !!profile;

  if (!creator || !creator.name || !creator.profileUrl) {
    captureMessage("Comment loaded without creator", { extra: { commentId: id, creator, profileId } });
  }

  return (
    <Card className={clsx("mb-2 mx-1", className)}>
      <CardContent>
        <div className="flex items-center">
          <Avatar src={creator?.avatarUrl || undefined} />
          <div className="ml-2 flex flex-col">
            <Link
              to={creator?.profileUrl || ""}
              onClick={() => tracking("comments-click-user-profile", "Dashboard Beta")}
            >
              <Text as="p" color="contrast" weight="medium" className="m-0">
                {creator?.name || "Unknown User"}
              </Text>
            </Link>
            <Text size="xs" color="muted">
              {tsString}
            </Text>
          </div>
        </div>
        <Text as="p" size="sm" className="mx-4 my-3">
          {content}
        </Text>
        {isLoggedIn && (
          <div className="flex justify-end">
            <Flagging commentId={id as string} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};
