import { visualization } from "@fscrypto/domain";
import { useQueryRunResult } from "../../../query/hooks/useQueryRunResult";
import { useVisualization, useVisualizationConfigBuilder } from "../../../visualization/hooks";
import { useDashboardCellContent } from "../useDashboardCellContent";

export const useVisualizationCellSettings = (cellId: string, dashboardId: string) => {
  const [{ visId, queryId }, setContent] = useDashboardCellContent(cellId, dashboardId, "visualization", "draft");
  const viz = useVisualization(visId!);
  const { result } = useQueryRunResult({ queryId: queryId! });
  const { options, inputs, fields, dataKeys } = useVisualizationConfigBuilder(visId!, result);

  const onChange = async (v: visualization.v3.Visualization) => {
    viz?.update(v);
  };

  const onOptionsChange = async (options: visualization.v3.Visualization["config"]["options"]) => {
    viz?.update({ ...viz?.value, config: { ...viz?.value?.config, options } });
  };

  const updateTitle = (title: string) => {
    setContent({ title });
  };

  return {
    viz: viz?.value,
    options,
    inputs,
    fields,
    dataKeys,
    onChange,
    onOptionsChange,
    updateTitle,
  };
};
