import { DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import React, { memo } from "react";
import { ReactNode } from "react";
import { useActiveCellListener } from "../../hooks/useActiveCellListener";
import { CellLabel, CellOptions } from "./components";

/**
 * ActiveCellListener component
 *
 * This component wraps dashboard cells and provides active cell functionality,
 * including hover effects, borders, and cell options.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the cell
 * @param {string} props.cellId - Unique identifier for the cell
 * @param {string} props.dashboardId - Identifier for the dashboard
 * @param {DashboardView} props.dashboardView - Current view mode of the dashboard
 * @param {boolean} [props.hideControls=false] - Whether to hide cell controls
 * @param {ReactNode} [props.customOptions] - Custom options to render in cell controls
 */
export const ActiveCellListener = ({
  children,
  cellId,
  dashboardId,
  dashboardView,
  hideControls = false,
  customOptions,
  preventMove = false,
}: {
  children: React.ReactNode;
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
  hideControls?: boolean;
  customOptions?: ReactNode;
  preventMove?: boolean;
}) => {
  // Use the custom hook to manage active cell state and interactions
  const { isActiveCell, isActiveLayout, handleClick, showHoverMask, showAncestorBorder, cell } = useActiveCellListener(
    cellId,
    dashboardId,
    dashboardView,
  );

  return (
    <div
      data-cell-id={cellId}
      className={clsx("size-full relative flex flex-1 flex-col", {
        // Apply different border styles based on cell state and dashboard view
        "border-primary rounded border-dashed border": showAncestorBorder,
        "border-primary rounded border border-dashed": (isActiveCell || isActiveLayout) && dashboardView === "draft",
        "border-transparent border":
          !((isActiveCell || isActiveLayout) && dashboardView === "draft") && !showAncestorBorder,
      })}
      onClick={handleClick}
    >
      {/* Render hover mask when cell is being hovered */}
      {showHoverMask && (
        <div className="size-full bg-primary absolute inset-0 z-20 rounded bg-opacity-10 pointer-events-none">
          <CellLabel cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
        </div>
      )}
      {/* Render children components with memoization for performance */}
      <MemoizedChildren>{children}</MemoizedChildren>
      {/* Render cell options when cell is active and controls are not hidden */}
      {!hideControls && (
        <CellOptions
          cellId={cellId}
          dashboardId={dashboardId}
          dashboardView={dashboardView}
          isActiveCell={isActiveCell}
          parentCellId={cell.parentCellId ?? "root"}
          preventMove={preventMove}
        >
          {customOptions ?? null}
        </CellOptions>
      )}
    </div>
  );
};

/**
 * MemoizedChildren component
 *
 * This component wraps children elements and memoizes them to prevent
 * unnecessary re-renders when the ActiveCellListener updates.
 *
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to memoize
 */
const MemoizedChildren = memo(({ children }: { children: React.ReactNode }) => <>{children}</>);

MemoizedChildren.displayName = "MemoizedChildren";
