import { DashboardView } from "@fscrypto/domain/dashboard";
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Tooltip } from "@fscrypto/ui";
import React from "react";
import { useParamCell } from "../../../hooks/param-cells/useParamCell";
import { useRunEphemeralQueryRunFromParamCell } from "../../../hooks/param-cells/useRunEphemeralQueryRunFromParamCell";
import { useDashboardCell } from "../../../hooks/useDashboardCell";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";

interface ParamsCellContentProps {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}

export const ParamsCellContent: React.FC<ParamsCellContentProps> = ({ cellId, dashboardId, dashboardView }) => {
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} preventMove>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
          <ParametersContent
            cellId={cellId}
            dashboardId={dashboardId}
            dashboardView={dashboardView}
            key={cell.parentCellId}
          />
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

interface ParametersProps {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}

export const ParametersContent = ({ cellId, dashboardId, dashboardView }: ParametersProps) => {
  const {
    visibleParameters = [],
    ephemeralValues,
    setEphemeralValues,
  } = useParamCell({
    dashboardId,
    paramCellId: cellId,
    dashboardView: "draft",
  });

  return (
    <div className="flex items-center justify-center h-full px-2 ">
      <div className="flex items-center gap-x-2 flex-wrap">
        {visibleParameters.map((parameter) => (
          <div
            key={parameter.id}
            onDragStart={(e) => e.stopPropagation()}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseMove={(e) => e.stopPropagation()}
          >
            {parameter.type === "list" ? (
              <SelectInput
                onChange={(val) => setEphemeralValues(parameter.name, val)}
                restrictedValues={parameter.restrictedValues ?? ""}
                disabled={false}
                value={ephemeralValues?.[parameter.name] ?? parameter.value}
                name={parameter.name}
              />
            ) : (
              <Input
                value={ephemeralValues?.[parameter.name] ?? parameter.value}
                startAddon={parameter.name}
                onChange={(e) => setEphemeralValues(parameter.name, e.target.value)}
                inputClassName="bg-background"
              />
            )}
          </div>
        ))}
        <RunParamsButton dashboardId={dashboardId} paramCellId={cellId} dashboardView={dashboardView} />
      </div>
    </div>
  );
};

interface SelectInputProps {
  name: string;
  value: string;
  onChange: (val: string) => void;
  restrictedValues: string;
  disabled: boolean;
}

export const SelectInput = ({ name, value, onChange, restrictedValues, disabled }: SelectInputProps) => {
  const values = restrictedValues.split(",");
  return (
    <Select onValueChange={onChange} value={value}>
      <SelectTrigger className="w-full">
        <div className="">{name}</div>
        <div className="text-border px-3">|</div>
        <SelectValue placeholder="Select tab" />
      </SelectTrigger>
      <SelectContent>
        {values.map((value) => (
          <SelectItem value={value} key={value}>
            {value}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

const RunParamsButton = ({
  dashboardId,
  paramCellId,
  dashboardView,
}: { dashboardId: string; paramCellId: string; dashboardView: DashboardView }) => {
  const { runParams, hasChanges } = useRunEphemeralQueryRunFromParamCell({
    dashboardId,
    paramCellId,
    dashboardView,
  });

  return (
    <Tooltip content="Change Values to Run Parameters" side="right" display={!hasChanges}>
      <Button
        disabled={!hasChanges}
        onClick={runParams}
        onDragStart={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseMove={(e) => e.stopPropagation()}
      >
        Run Params
      </Button>
    </Tooltip>
  );
};
