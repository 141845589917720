import { quest, tag } from "@fscrypto/domain";
import { DashboardView } from "@fscrypto/domain/dashboard";
import { questEcosystemSchema } from "@fscrypto/domain/quest";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import { $path } from "remix-routes";
import { tracking } from "~/utils/tracking";
import { useDashboardSettings } from "../../../hooks/useDashboardSettings";

export const QuestLink = ({ dashboardId, dashboardView }: { dashboardId: string; dashboardView: DashboardView }) => {
  const { questEcosystemProjects, dashboardTags } = useDashboardSettings(dashboardId);

  if (dashboardView !== "published") {
    return null;
  }
  const intersection = questEcosystemProjects?.filter((eco) => dashboardTags?.some((dash) => dash.id === eco.id)) ?? [];

  const params = { dashboardId };
  const encoded = Buffer.from(JSON.stringify(params), "binary").toString("base64");

  // base values when no project intersection
  let url = $path("/earn", { ref: encoded });
  let copy = "Try Quests to earn crypto";
  let questProject: tag.Tag | undefined = undefined;
  let currency: string | undefined = undefined;
  let iconFileName = "flipside.svg";

  // if there is a project intersection, use the first one
  if (intersection.length > 0) {
    questProject = intersection[0];
    url = $path("/earn/:ecosystem", { ecosystem: questProject.name }, { ref: encoded });
    const eco = questEcosystemSchema.safeParse(questProject!.name);
    currency = eco.success ? quest.questConfigs[eco.data].currency : "crypto";
    copy = `Try Quests to earn ${currency}`;
    if (questProject.iconFileName) iconFileName = questProject.iconFileName;
  }

  return (
    <Link
      to={url}
      className="bg-muted text-muted-foreground mb-4 mr-2 flex min-w-[220px] items-center whitespace-nowrap rounded-full p-2 px-3 text-sm font-medium shadow md:mb-0 md:mr-20"
      onClick={() =>
        tracking("dashboard_quest_link_click", "Dashboard", {
          dashboardId: dashboardId,
          questProjectName: questProject?.name ?? "none",
        })
      }
      target="_blank"
    >
      <img
        className={clsx("mr-2", { "h-4 w-4": !questProject }, { "h-6 w-6": questProject })}
        alt={copy}
        src={`https://res.cloudinary.com/dsr37ut2z/image/upload/assets/project-icons/${iconFileName}`}
      />
      {copy}
    </Link>
  );
};
