import { Text } from "@fscrypto/ui";
import clsx from "clsx";
import { ColorPicker } from "@fscrypto/ui";
import { TextColorStyle } from "node_modules/@fscrypto/domain/src/dashboard/configSchema";

export const TextColorField = ({
  textColor,
  onChange,
}: {
  textColor?: TextColorStyle;
  onChange: (bg: TextColorStyle) => void;
}) => {
  return <SolidColors textColor={textColor} onChange={onChange} />;
};

const SolidColors = ({
  textColor,
  onChange,
}: {
  textColor?: TextColorStyle;
  onChange: (bg: TextColorStyle) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <AppTextColorSwatches textColor={textColor} onChange={onChange} />
      <ColorPicker
        onChange={(color) => {
          onChange({ ...textColor, color1: color, appColor: undefined });
        }}
        value={textColor?.color1 ?? "#fff"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text size="xs" color="muted">
              Custom Color
            </Text>
            <Swatch
              bgColor={color ?? "#fff"}
              isActive={textColor?.type === "solid" && textColor.color1 === color && !textColor.appColor}
            />
          </div>
        )}
      />
    </div>
  );
};

const AppTextColorSwatches = ({
  textColor,
  onChange,
}: {
  textColor?: TextColorStyle;
  onChange: (bg: TextColorStyle) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Text size="xs" color="muted">
        App Colors
      </Text>
      <div className="grid grid-cols-6 gap-2">
        {Object.keys(appTextColors).map((key) => {
          return (
            <AppTextColorSwatch
              colorKey={key as BGColorKey}
              key={key}
              isActive={textColor?.type === "solid" && textColor.appColor === appTextColors[key as BGColorKey]}
              onClick={() => {
                onChange({ ...textColor, appColor: key });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const AppTextColorSwatch = ({
  colorKey,
  isActive,
  onClick,
}: {
  colorKey: keyof typeof appTextColors;
  isActive: boolean;
  onClick: (key: keyof typeof appTextColors) => void;
}) => {
  const colorStyle = appTextColors[colorKey];
  return (
    <button
      onClick={() => onClick(colorKey)}
      className={clsx("size-8 rounded-lg border shadow", colorStyle, { "border-primary border-2": isActive })}
    ></button>
  );
};

const Swatch = ({ bgColor, isActive }: { bgColor: string; isActive: boolean }) => (
  <div
    className={clsx("size-8 rounded-lg border shadow", { "border-primary border-2": isActive })}
    style={{ background: bgColor }}
  ></div>
);

export const appTextColors = {
  "app-foreground": "bg-foreground",
  "app-bg": "bg-background",
  "app-muted": "bg-muted",
  "app-primary": "bg-primary",
  "app-success": "bg-success",
  "app-warning": "bg-warning",
};

type BGColorKey = keyof typeof appTextColors;
