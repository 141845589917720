import { useLocation, useSubmit } from "@remix-run/react";
import { $path } from "remix-routes";

export const useAuthRedirect = () => {
  const location = useLocation();
  const submit = useSubmit();
  const baseUrl = typeof window === "undefined" ? process.env.BASE_URL : window.ENV.BASE_URL;
  const redirectUrl = `${baseUrl}${location.pathname}${location.search}${location.hash}`;

  const authRedirect = () =>
    submit(null, {
      method: "post",
      action: $path("/auth/auth0", { screen_hint: "signup", redirectUrl }),
    });

  return { authRedirect };
};
