import { DashboardView } from "@fscrypto/domain/dashboard";
import { Button } from "@fscrypto/ui";
import { Tabs, TabsList, TabsTrigger } from "@fscrypto/ui/tabs";
import clsx from "clsx";
import { BlocksIcon, PlusIcon } from "lucide-react";
import { nanoid } from "nanoid";
import { DynamicIcon } from "~/shared/components/dynamic-icon";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardCell } from "../../../hooks/useDashboardCell";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useTabState } from "../../../hooks/useTabState";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";
import { TabLayoutCellContent } from "../tab-layout-cell/tab-layout-cell-content";

export const TabsPanelCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={clsx("flex w-full flex-col")}>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
          <div className="flex  flex-1 flex-col p-1">
            <ConfigTabs cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
            <Content cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};

const ConfigTabs = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  const { activeTabId, setActiveTabId } = useTabState(cellId, dashboardId, dashboardView);
  const { addDashboardCell } = useDashboard(dashboardId);
  const { setActiveLayoutId } = useDashboardEditor({ dashboardId });
  const tabIds = cell?.childCellIds || [];
  return (
    <Tabs value={activeTabId ?? undefined} className="flex flex-col">
      <TabsList className="flex items-center bg-transparent" size="md">
        {tabIds.map((tabId) => (
          <TabsPanelTrigger
            cellId={tabId}
            key={tabId}
            parentCellId={cellId}
            dashboardId={dashboardId}
            dashboardView={dashboardView}
          />
        ))}
        {dashboardView === "draft" && (
          <Button
            size="sm"
            iconOnly
            className=""
            onClick={(e) => {
              e.stopPropagation();
              const newId = `tab-layout-${nanoid(4)}`;
              addDashboardCell?.({
                id: newId,
                variant: "tab-layout",
                parentId: cellId,
                content: {
                  title: `New Tab ${cell?.childCellIds?.length ?? 0 + 1}`,
                },
                style: {
                  border: {
                    width: 0,
                  },
                },
              });
              setActiveLayoutId(newId, true);
              setActiveTabId(newId);
            }}
          >
            <PlusIcon className="size-7" />
          </Button>
        )}
      </TabsList>
    </Tabs>
  );
};

const TabsPanelTrigger = ({
  cellId,
  parentCellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  parentCellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const [{ title, icon, iconColor }] = useDashboardCellContent(cellId, dashboardId, "base", dashboardView);
  const { activeTabId, setActiveTabId } = useTabState(parentCellId, dashboardId, dashboardView);
  const { setActiveLayoutId, setActiveCellId } = useDashboardEditor({ dashboardId });
  return (
    <TabsTrigger
      data-cell-id={cellId}
      value={cellId}
      size="md"
      className={clsx("min-w-24 border px-2", {
        "opacity-50": activeTabId !== cellId,
      })}
      onClick={(e) => {
        e.stopPropagation();
        setActiveTabId(cellId);
        setActiveLayoutId(cellId);
      }}
    >
      {icon && <DynamicIcon name={icon} className="size-4 display-inline mr-2" style={{ color: iconColor }} />}
      {title}
    </TabsTrigger>
  );
};

const Content = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { activeTabId } = useTabState(cellId, dashboardId, dashboardView);
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  if (!activeTabId || cell.childCellIds?.length === 0)
    return <EmptyTabsPanel cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />;
  return (
    <TabLayoutCellContent
      cellId={activeTabId}
      dashboardId={dashboardId}
      dashboardView={dashboardView}
      key={activeTabId}
    />
  );
};

const EmptyTabsPanel = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { setActiveTabId } = useTabState(cellId, dashboardId, dashboardView);
  const { cell } = useDashboardCell(cellId, dashboardId, dashboardView);
  const { addDashboardCell } = useDashboard(dashboardId);
  const { setActiveLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <div className=" size-full text-muted-foreground flex flex-1 flex-col items-center justify-start gap-y-3">
      <BlocksIcon className="mx-auto h-12 w-12" />
      {dashboardView === "draft" && (
        <Button
          onClick={() => {
            const newId = `tab-layout-${nanoid(4)}`;

            addDashboardCell?.({
              id: newId,
              variant: "tab-layout",
              parentId: cellId,
              content: {
                title: `New Tab ${cell?.childCellIds?.length ?? 0 + 1}`,
              },
              style: {
                border: {
                  width: 0,
                },
              },
            });
            setActiveLayoutId(newId, true);
            setActiveTabId(newId);
          }}
        >
          Add a tab to get started
        </Button>
      )}
    </div>
  );
};
