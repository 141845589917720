import { Label } from "@fscrypto/ui";
import { FileUploader } from "~/features/file-uploader/ui/file-uploader";

export const ImageInput = ({
  url,
  onUpload,
  id,
  onRemove,
  showLabel,
}: {
  id: string;
  url?: string;
  onUpload: (url: string) => void;
  onRemove?: () => void;
  showLabel?: boolean;
}) => {
  return (
    <div>
      {showLabel && (
        <div className="flex w-full items-center justify-between">
          <Label className="text-muted-foreground">Image</Label>
        </div>
      )}
      <FileUploader initialUrl={url} onUpload={onUpload} id={id} onRemove={onRemove} />
    </div>
  );
};
