import { Textarea } from "@fscrypto/ui";
import sanitizeHtml from "sanitize-html";
export const cleanHtml = (html: string) => {
  return sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["style"]),
    allowedAttributes: { ...sanitizeHtml.defaults.allowedAttributes, "*": ["style"] },
    allowVulnerableTags: true,
  });
};

export const MarkdownField = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
  return (
    <div>
      <Textarea defaultValue={value} onChange={(e) => onChange(e.target.value)} rows={16} />
    </div>
  );
};
