import { DashboardView } from "@fscrypto/domain/dashboard";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";
import { GridLayout } from "../../editor/dashboard-grid";

export const LayoutCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView);
  const { activeLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={"w-full"}>
            <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
          </div>
          <div className="flex flex-1 flex-col">
            <GridLayout
              dashboardView={dashboardView}
              dashboardId={dashboardId}
              layoutId={cellId}
              layout={layout}
              setLayout={(layout) => {
                updateLayouts?.(layout);
              }}
              draggable={activeLayoutId === cellId && dashboardView === "draft"}
            />
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};
