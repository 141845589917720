import { Button, Field, Input, Label, Switch, Text } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import { XIcon } from "lucide-react";
import { ReactNode } from "react";
import { useDashboardCellContent } from "../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { IconField } from "../inputs/icon-field";

export const ConfigTitleAndClose = ({
  title,
  dashboardId,
  hideClose,
}: {
  title: string;
  dashboardId: string;
  hideClose?: boolean;
}) => {
  const { setActiveCellId } = useDashboardEditor({ dashboardId });
  return (
    <div className="flex items-center justify-between">
      <Text size="lg" weight="semi">
        {title}
      </Text>
      {!hideClose && (
        <Button variant={"ghost"} size="sm" iconOnly onClick={() => setActiveCellId?.("root")}>
          <XIcon className="size-4" />
        </Button>
      )}
    </div>
  );
};

export const TitleDescription = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [cellContent, setContent] = useDashboardCellContent(cellId, dashboardId, "base", "draft");
  return (
    <ConfigAccordion>
      <ConfigAccordionItem label="Title and Description">
        <Field>
          <div className="flex w-full items-center justify-between">
            <Label className="text-muted-foreground">Title</Label>
            <Switch
              checked={cellContent?.showTitle}
              onCheckedChange={(showTitle) => setContent({ showTitle: showTitle })}
            />
          </div>
          <Input
            value={cellContent?.title ?? ""}
            onChange={(e) => setContent({ title: e.target.value })}
            disabled={!cellContent?.showTitle}
          />
        </Field>
        <Field>
          <div className="flex w-full items-center justify-between">
            <Label className="text-muted-foreground">Description</Label>
            <Switch
              checked={cellContent?.showDescription}
              onCheckedChange={(showDescription) => setContent({ showDescription: showDescription })}
            />
          </div>

          <Input
            value={cellContent?.description ?? ""}
            onChange={(e) => setContent({ description: e.target.value })}
            disabled={!cellContent?.showDescription}
          />
        </Field>
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};

export const TitleOnly = ({ cellId, dashboardId, label }: { cellId: string; dashboardId: string; label: string }) => {
  const [cellContent, setContent] = useDashboardCellContent(cellId, dashboardId, "base", "draft");
  return (
    <ConfigAccordion>
      <ConfigAccordionItem label={label}>
        <Field>
          <Input value={cellContent?.title ?? ""} onChange={(e) => setContent({ title: e.target.value })} />
        </Field>
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};

export const ConfigAccordion = ({ children, value }: { children: ReactNode; value?: string[] }) => {
  return (
    <Accordion type="multiple" defaultValue={value}>
      {children}
    </Accordion>
  );
};

export const ConfigAccordionItem = ({ children, label }: { children: ReactNode; label: string }) => {
  return (
    <AccordionItem value={label} key={label} className="">
      <AccordionTrigger caretPosition="left" className="py-4">
        <Text weight="semi" className="px-2">
          {label}
        </Text>
      </AccordionTrigger>
      <AccordionContent className="flex flex-col gap-y-4 w-full">{children}</AccordionContent>
    </AccordionItem>
  );
};

export const CustomIcon = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [cellContent, setContent] = useDashboardCellContent(cellId, dashboardId, "base", "draft");
  return (
    <ConfigAccordion>
      <ConfigAccordionItem label="Custom Icon">
        <IconField
          icon={cellContent?.icon ?? ""}
          onIconChange={(icon) => setContent({ icon })}
          iconColor={cellContent?.iconColor ?? "#000"}
          onIconColorChange={(iconColor) => setContent({ iconColor })}
        />
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};
