import { Switch, Text } from "@fscrypto/ui";
import { DragControls, Reorder, useDragControls } from "framer-motion";
import { GripVerticalIcon } from "lucide-react";
import { useParamCell } from "../../../hooks/param-cells/useParamCell";
import { ConfigAccordion, ConfigAccordionItem } from "../../config-elements/config-elements";

export const ParametersConfig = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <ConfigAccordion value={["Parameters"]}>
      <ConfigAccordionItem label="Parameters">
        <ParamList cellId={cellId} dashboardId={dashboardId} />
      </ConfigAccordionItem>
    </ConfigAccordion>
  );
};

function ParamList({ cellId, dashboardId }: { cellId: string; dashboardId: string }) {
  const { orderedParameters, hiddenParams, toggleHidden, setParamIdOrder } = useParamCell({
    dashboardId,
    paramCellId: cellId,
    dashboardView: "draft",
  });
  return (
    <div className="py-3">
      <Reorder.Group
        axis="y"
        values={orderedParameters.map((p) => p.id)}
        onReorder={(ids) => {
          setParamIdOrder(ids);
        }}
        className="flex w-full flex-col gap-y-1"
      >
        {orderedParameters.map((param) => (
          <DragItem
            paramId={param.id}
            key={param.id}
            name={param.name}
            onToggleHide={toggleHidden}
            isHidden={hiddenParams.includes(param.id)}
          />
        ))}
      </Reorder.Group>
    </div>
  );
}

const DragItem = ({
  paramId,
  name,
  onToggleHide,
  isHidden,
}: {
  paramId: string;
  name: string;
  onToggleHide: (id: string) => void;
  isHidden: boolean;
}) => {
  const dragControls = useDragControls();
  return (
    <Reorder.Item
      key={paramId}
      value={paramId}
      className="flex w-full items-center"
      dragListener={false}
      dragControls={dragControls}
    >
      <Parameter
        dragControls={dragControls}
        name={name}
        onToggleHide={() => onToggleHide(paramId)}
        isHidden={isHidden}
      />
    </Reorder.Item>
  );
};

const Parameter = ({
  dragControls,
  name,
  onToggleHide,
  isHidden = false,
}: {
  dragControls: DragControls;
  name: string;
  onToggleHide: () => void;
  isHidden: boolean;
}) => {
  return (
    <div className="bg-background flex w-full items-center  justify-between truncate rounded border p-1">
      <div
        className="draggable-handle bg-muted flex h-8 items-center justify-center rounded border shadow-sm"
        style={{ cursor: "grab" }}
        onPointerDown={(event) => {
          event.stopPropagation();
          dragControls.start(event);
        }}
        onClick={(event) => event.stopPropagation()}
      >
        <GripVerticalIcon className="size-6 text-muted-foreground" />
      </div>
      <div className="flex w-full items-center justify-between">
        <Text className="w-full select-none overflow-hidden truncate px-2 text-left" weight={"medium"} size="sm">
          {name}
        </Text>
        <Switch checked={!isHidden} onCheckedChange={onToggleHide} />
      </div>
    </div>
  );
};
