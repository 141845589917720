import { ConfigTitleAndClose } from "../../config-elements/config-elements";
import { BackgroundStyles, StylesAccordion, TitleStyles } from "../../style-elements/style-elements";

export const TabsPanelCellStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col p-3">
      <ConfigTitleAndClose title="Tabs Panel Styles" dashboardId={dashboardId} />
      <StylesAccordion>
        <TitleStyles cellId={cellId} dashboardId={dashboardId} />
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} />
      </StylesAccordion>
    </div>
  );
};
