import { ContentByVariant, DashboardView } from "@fscrypto/domain/dashboard";
import { dashboardFactory } from "../state/dashboard";
import { useEntity, useOptionalStore } from "@fscrypto/state-management";
export const useRootCellContent = (dashboardId: string, dashboardView: DashboardView): ContentByVariant<"root"> => {
  const db = useEntity(dashboardFactory, dashboardId);
  const content = useOptionalStore(db?.store, (s) =>
    dashboardView === "published" ? s.publishedConfig?.contents["root"] : s.draftConfig.contents["root"],
  );

  return (content ?? {}) as ContentByVariant<"root">;
};
