import { CellInfo, DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useCallback, useMemo } from "react";
import { dashboardFactory } from "../state/dashboard";

export const useDashboardCell = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const cell = useStoreWithDynamicSelector(db?.store, (s) =>
    dashboardView === "published" ? s.publishedConfig?.cells[cellId] : s.draftConfig.cells[cellId],
  );

  const setCell = useCallback(
    (cell: Partial<CellInfo>) => {
      if (db) {
        const newDb = dashboardDomain.setCell(db.store.get(), cellId, cell);
        db.update(newDb);
      }
    },
    [cellId, db],
  );

  const toggleOpen = useCallback(() => {
    if (db) {
      const newDb = dashboardDomain.toggleOpen(db.store.get(), cellId);
      db.update(newDb);
    }
  }, [cellId, db]);

  const cellOrDefault = useMemo(() => (cell ?? {}) as CellInfo, [cell]);

  return { cell: cellOrDefault, setCell, toggleOpen };
};
