import { UploadIcon } from "lucide-react";
import { useFileUpload } from "../state/file-uploader-entity";
import { Button } from "@fscrypto/ui";

export const FileUploader = ({
  id,
  onUpload,
  initialUrl,
  onRemove,
}: {
  id: string;
  onUpload: (url: string, id: string) => void;
  initialUrl?: string;
  onRemove?: () => void;
}) => {
  const { url, uploadFile, removeFile } = useFileUpload(id, initialUrl, onUpload, onRemove);
  return (
    <div className="flex flex-col gap-y-2 p-2">
      {url ? (
        <img
          src={url}
          alt="db_img"
          className="h-32 w-full space-y-1 rounded-lg border-2 border-dashed object-contain"
        />
      ) : (
        <div className="flex h-32 flex-col items-center justify-center space-y-1 rounded-lg border-2 border-dashed p-5">
          <UploadIcon className="text-muted-foreground size-5 m-2 flex-shrink-0" />
          <div className="text-muted-foreground flex">
            <label htmlFor="file" className="bg-background relative m-2 cursor-pointer rounded-md p-2 font-medium ">
              <span className="text-primary text-xs">Browse files to upload</span>
              <input
                id="file"
                name="file"
                type="file"
                accept="image/*"
                className="hidden"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const file = e?.target?.files?.[0];
                  if (file) {
                    uploadFile?.(file);
                  }
                }}
              />
            </label>
          </div>
        </div>
      )}
      {url && (
        <Button
          variant="warning"
          size={"xs"}
          onClick={() => {
            removeFile?.();
          }}
          className="w-32"
        >
          Remove
        </Button>
      )}
    </div>
  );
};
