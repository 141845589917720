import { Text } from "@fscrypto/ui";
import { ConfigTitleAndClose, CustomIcon, TitleDescription } from "../../config-elements/config-elements";

export const LayoutCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <ConfigTitleAndClose title="Layout Panel" dashboardId={dashboardId} />
      <Text size="sm" color={"muted"}>
        This panel allows you to nest other panels.
      </Text>
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};
