import { BaseCellStyles } from "@fscrypto/domain/dashboard";
import { ColorPicker, Slider, Text } from "@fscrypto/ui";
import clsx from "clsx";
import { BanIcon } from "lucide-react";

export const BorderField = ({
  border,
  onChange,
}: {
  border: BaseCellStyles["border"];
  onChange: (border: BaseCellStyles["border"]) => void;
}) => {
  const borderStyles = border ?? defaultBorder;
  return (
    <div className="flex flex-col gap-y-4 px-2">
      <BorderColor border={borderStyles} onChange={onChange} />
      <BorderStyles border={borderStyles} onChange={onChange} />
      <BorderWidth border={borderStyles} onChange={onChange} />
      <BorderRadius border={borderStyles} onChange={onChange} />
    </div>
  );
};

const BorderColor = ({
  border,
  onChange,
}: {
  border: BaseCellStyles["border"];
  onChange: (bg: BaseCellStyles["border"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <ColorPicker
        onChange={(color) => {
          onChange({ ...border, color });
        }}
        value={border?.color ?? "#fff"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text size="xs" color="muted">
              Border Color
            </Text>
            <Swatch color={color ?? "#fff"} />
          </div>
        )}
      />
    </div>
  );
};

const BorderStyles = ({
  border,
  onChange,
}: {
  border: BaseCellStyles["border"];
  onChange: (border: BaseCellStyles["border"]) => void;
}) => {
  const style = border?.style ?? { style: "none" };
  return (
    <div className="flex w-full flex-col gap-y-4">
      <Text size="xs" color="muted">
        Border Style
      </Text>
      <div className="grid grid-cols-5">
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 shadow", {
            "border-primary border-2": style === "none",
            "border border-none": style !== "none",
          })}
          onClick={() => onChange({ ...border, style: "none" })}
        >
          {<BanIcon className="size-4 text-muted-foreground" />}
        </button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 border-solid shadow", {
            "border-primary border-2": style === "solid",
          })}
          onClick={() => onChange({ ...border, style: "solid" })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 border-dashed shadow", {
            "border-primary border-2": style === "dashed",
          })}
          onClick={() => onChange({ ...border, style: "dashed" })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 border-dotted shadow", {
            "border-primary border-2": style === "dotted",
          })}
          onClick={() => onChange({ ...border, style: "dotted" })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 border-double shadow", {
            "border-primary border-2": style === "double",
          })}
          onClick={() => onChange({ ...border, style: "double" })}
        ></button>
      </div>
    </div>
  );
};

const BorderWidth = ({
  border,
  onChange,
}: {
  border: BaseCellStyles["border"];
  onChange: (border: BaseCellStyles["border"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <Text size="xs" color="muted">
        Border Thickness
      </Text>
      <div className="grid grid-cols-5">
        <button
          className={clsx("size-8 flex items-center justify-center rounded border shadow", {
            "border-primary border": border?.width === 1,
          })}
          onClick={() => onChange({ ...border, width: 1 })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-2 shadow", {
            "border-primary border-2": border?.width === 2,
          })}
          onClick={() => onChange({ ...border, width: 2 })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-[3px] shadow", {
            "border-primary border-[3px]": border?.width === 3,
          })}
          onClick={() => onChange({ ...border, width: 3 })}
        ></button>
        <button
          className={clsx("size-8 flex items-center justify-center rounded border-[4px] shadow", {
            "border-primary border-[4px]": border?.width === 4,
          })}
          onClick={() => onChange({ ...border, width: 4 })}
        ></button>
      </div>
    </div>
  );
};

const Swatch = ({ color }: { color: string }) => (
  <div className={clsx("size-8 rounded-lg border shadow")} style={{ background: color }}></div>
);

const BorderRadius = ({
  border,
  onChange,
}: {
  border: BaseCellStyles["border"];
  onChange: (border: BaseCellStyles["border"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <Text size="xs" color="muted">
        Border Radius
      </Text>
      <Slider
        value={[border?.radius ?? 0]}
        onValueChange={([vl]) => onChange({ ...border, radius: vl })}
        max={20}
        min={0}
        step={4}
      />
      <div className="w-full text-center">{border?.radius ?? 0}px</div>
    </div>
  );
};

const defaultBorder = {
  width: 1,
  style: "solid",
  radius: 8,
} as BaseCellStyles["border"];
