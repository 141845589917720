import { BaseCellStyles } from "@fscrypto/domain/dashboard";
import clsx from "clsx";

export const ShadowField = ({
  shadow,
  onChange,
}: {
  shadow: BaseCellStyles["shadow"];
  onChange: (shadow: BaseCellStyles["shadow"]) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-4 px-2">
      <ShadowWidth shadow={shadow} onChange={onChange} />
    </div>
  );
};

const ShadowWidth = ({
  shadow,
  onChange,
}: {
  shadow: BaseCellStyles["shadow"];
  onChange: (shadow: BaseCellStyles["shadow"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <div className="grid grid-cols-2 gap-4 pb-12">
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold",
            { "border-primary border": shadow === "none" },
          )}
          onClick={() => onChange("none")}
        >
          none
        </button>
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold shadow-sm",
            { "border-primary border": shadow === "sm" },
          )}
          onClick={() => onChange("sm")}
        >
          sm
        </button>
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold shadow-md",
            { "border-primary border": shadow === "md" },
          )}
          onClick={() => onChange("md")}
        >
          md
        </button>
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold shadow-lg",
            { "border-primary border": shadow === "lg" },
          )}
          onClick={() => onChange("lg")}
        >
          lg
        </button>
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold shadow-xl",
            { "border-primary border": shadow === "xl" },
          )}
          onClick={() => onChange("xl")}
        >
          xl
        </button>
        <button
          className={clsx(
            "size-24 bg-background text-muted-foreground flex items-center justify-center rounded text-sm font-semibold shadow-2xl",
            { "border-primary border": shadow === "2xl" },
          )}
          onClick={() => onChange("2xl")}
        >
          2xl
        </button>
      </div>
    </div>
  );
};

// const Swatch = ({ color }: { color: string }) => (
//   <div className={clsx("size-8 rounded-lg border shadow")} style={{ background: color }}></div>
// );
