import { Button } from "@fscrypto/ui";
import BulletList from "@tiptap/extension-bullet-list";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  ItalicIcon,
  ListIcon,
  UnderlineIcon,
} from "lucide-react";
import React, { ChangeEvent, useEffect, useRef } from "react";
import "./styles.css";

interface MenuBarProps {
  editor: Editor | null;
}

const MenuBar: React.FC<MenuBarProps> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="mb-1 flex gap-x-1">
      {/* Bold */}
      <Button
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().toggleBold()}
        variant="editor"
        size="editor"
        active={editor.isActive("bold")}
      >
        <BoldIcon size={16} />
      </Button>

      {/* Italic */}
      <Button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().toggleItalic()}
        variant="editor"
        size="editor"
        active={editor.isActive("italic")}
      >
        <ItalicIcon size={16} />
      </Button>

      {/* Underline */}
      <Button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        disabled={!editor.can().toggleUnderline()}
        variant="editor"
        size="editor"
        active={editor.isActive("underline")}
      >
        <UnderlineIcon size={16} />
      </Button>

      {/* Align Left */}
      <Button
        onClick={() => editor.chain().focus().setTextAlign("left").run()}
        disabled={!editor.can().setTextAlign("left")}
        variant="editor"
        size="editor"
        active={editor.isActive({ textAlign: "left" })}
      >
        <AlignLeftIcon size={16} />
      </Button>

      {/* Align Center */}
      <Button
        onClick={() => editor.chain().focus().setTextAlign("center").run()}
        disabled={!editor.can().setTextAlign("center")}
        variant="editor"
        size="editor"
        active={editor.isActive({ textAlign: "center" })}
      >
        <AlignCenterIcon size={16} />
      </Button>

      {/* Align Right */}
      <Button
        onClick={() => editor.chain().focus().setTextAlign("right").run()}
        disabled={!editor.can().setTextAlign("right")}
        variant="editor"
        size="editor"
        active={editor.isActive({ textAlign: "right" })}
      >
        <AlignRightIcon size={16} />
      </Button>

      {/* Bullet List */}
      <Button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().toggleBulletList()}
        variant="editor"
        size="editor"
        active={editor.isActive("bulletList")}
      >
        <ListIcon size={16} />
      </Button>
      <ColorPickerButton editor={editor} />

      {/* Color Picker (Assuming you have a method to pick color) */}
      {/* <Button
        onClick={() => editor.chain().focus().setColor("#ff0000").run()} // You might need a color picker here
        disabled={!editor.can().setColor("#ff0000")}
        variant="editor"
        size="editor"
      >
        <DropletIcon size={16} />
      </Button> */}
    </div>
  );
};

interface TextEditorProps {
  value: string;
  onChange: (newValue: string) => void;
}

export const TextEditor = ({ value = "", onChange }: TextEditorProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        alignments: ["left", "right", "center"],
      }),
      BulletList.configure({
        HTMLAttributes: {
          class: "list-disc pl-5",
        },
      }),
    ],
    editorProps: {
      attributes: {
        class: "border shadow rounded-lg min-h-[300px] p-2 max-w-full break-words overflow-hidden ",
      },
    },
    content: value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      onChange(html);
    },
  });

  // Ensure external updates to value prop are reflected in the editor
  useEffect(() => {
    if (editor && value !== editor.getHTML()) {
      editor.commands.setContent(value);
    }
  }, [value, editor]);

  return (
    <div className="w-full max-w-full overflow-hidden break-words flex flex-col min-w-0">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} className="" />
    </div>
  );
};

const ColorPickerButton = ({ editor }: { editor: Editor | null }) => {
  const hiddenColorInputRef = useRef<HTMLInputElement>(null);
  if (!editor) {
    return null;
  }

  // Function to handle color change
  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    editor.commands.setColor(color); // Apply color to the editor
  };
  const handleClick = () => {
    hiddenColorInputRef.current?.click();
  };

  return (
    <div className="size-6 relative mt-1 rounded-full">
      <button
        onClick={handleClick}
        style={{ backgroundColor: getCurrentColor(editor) }}
        className="size-6 rounded-full"
      ></button>
      <input
        ref={hiddenColorInputRef}
        type="color"
        value={getCurrentColor(editor)}
        onChange={handleColorChange}
        className="size-7 overflow-hidden rounded-full"
        style={{ visibility: "hidden", position: "absolute", height: 0, width: 0 }}
      />
    </div>
  );
};

const getCurrentColor = (editor: Editor | null) => {
  if (!editor) {
    return "#000000"; // Default color or null if you prefer
  }

  // Get the current attributes from the selection
  const attrs = editor.getAttributes("textStyle");

  // The color attribute is based on how you've defined the attribute in your Color extension
  return attrs.color || "#000000"; // Default to black or another color if not set
};
