import { CellVariant, DashboardView, StylesByVariant } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { dashboardFactory } from "../state/dashboard";
import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { useCallback } from "react";
// import { dashboard } from "@fscrypto/domain";

export const useDashboardCellStyles = <V extends CellVariant>(
  cellId: string,
  dashboardId: string,
  variant: V,
  dashboardView: DashboardView,
): [Partial<StylesByVariant<V>>, (c: Partial<StylesByVariant<V>>) => void] => {
  const db = useEntity(dashboardFactory, dashboardId);
  const styles = useOptionalStore(db?.store, (s) => {
    return dashboardView === "published" ? s.publishedConfig?.styles[cellId] : s.draftConfig.styles[cellId];
  });

  // This gets the default styles for the dashboard
  const rootStyles = useOptionalStore(db?.store, (s) =>
    dashboardView === "published" ? s.publishedConfig?.styles["root"] : s.draftConfig.styles["root"],
  );

  const setStyles = useCallback(
    (cellStyles: Partial<StylesByVariant<V>>) => {
      if (db) {
        const newDb = dashboardDomain.setCellStyles<V>(db.store.get(), cellId, cellStyles);
        db.update(newDb);
      }
    },
    [cellId, db],
  );
  if (!db || !styles) {
    return [{}, () => {}];
  }

  // this merges the root styles with the cell styles so that if there are no explicit cell styles it uses the default root styles
  const mergedStyles = { ...rootStyles, ...styles } as StylesByVariant<V>;

  if (variant === "base") {
    return [mergedStyles as StylesByVariant<V>, setStyles];
  }

  const isCellStyle = dashboardDomain.isCellStyleVariant(styles, variant);
  if (!isCellStyle) {
    throw new Error("Incorrect use of cell content variant");
  }
  return [mergedStyles, setStyles];
};
