import { DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useMemo } from "react";
import { dashboardFactory } from "../../state/dashboard";

/**
 * A custom hook that finds the closest parameter cell ID for a given cell in a dashboard.
 * This is important for cells like visualizations that will need to traverse up the tree to find the closest parameter cell.
 *
 * @param {string} dashboardId - The ID of the dashboard.
 * @param {string} cellId - The ID of the cell for which to find the closest parameter cell.
 * @param {DashboardView} dashboardView - The view of the dashboard (e.g., "draft" or "published").
 * @returns {string | undefined} The ID of the closest parameter cell, or undefined if not found.
 */
export const useClosestParamCell = (dashboardId: string, cellId: string, dashboardView: DashboardView) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const dashboardData = useStoreWithDynamicSelector(db?.store, (s) => s);

  const closestParamCellId = useMemo(() => {
    if (!dashboardData) return undefined;
    const closestId = dashboardDomain.findClosestParamCellId(dashboardData, cellId, dashboardView);
    return closestId;
  }, [dashboardData, cellId, dashboardView]);

  return closestParamCellId;
};
