import type { Comment as CommentType } from "@fscrypto/domain/comments";

import { Button, Drawer, DrawerContent, DrawerTitle, Text, Textarea } from "@fscrypto/ui";
import clsx from "clsx";
import { MessageCircleIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useCurrentUser } from "~/features/current-user";
import { useAuthRedirect } from "~/hooks/useAuthRedirect";
import { tracking } from "~/utils/tracking";
import { useComments } from "../state/useComments";
import { Comment } from "./comment";

const EmptyComments = () => {
  return (
    <div className="flex flex-col mt-[10%] lg:mt-[20%] flex-grow">
      <MessageCircleIcon className="size-40 mx-auto text-gray-300" />
      <div className="text-lg font-bold text-center mt-4">Be the first to share your thoughts!</div>
      <div className="text-sm text-gray-800 text-center">
        Leave feedback, ask questions, or request additional metrics.
      </div>
    </div>
  );
};

type CommentsProps = {
  isOpen: boolean;
  onClose: () => void;
  resourceId: string;
  resourceType: CommentType["resourceType"];
};

export const CommentsPanel = ({ isOpen, onClose, resourceId, resourceType }: CommentsProps) => {
  const maxContentLength = 1000;
  const minContentLength = 2;
  const [newComment, setNewComment] = useState("");
  const [hasTrackedInteraction, setHasTrackedInteraction] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { comments, add } = useComments(resourceType, resourceId);
  const { profile } = useCurrentUser();
  const { authRedirect } = useAuthRedirect();

  const isLoggedIn = !!profile;

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.lastElementChild?.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments.length]);

  const addComment = () => {
    add(newComment);
    tracking("comments-add-comment", "Dashboard Beta");
    setNewComment("");
    setHasTrackedInteraction(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > maxContentLength) {
      return;
    }

    setNewComment(e.target.value);
  };

  const trackedClose = () => {
    tracking("dashboard-comments-close", "Dashboard Beta");
    onClose();
  };

  const onLoginClick = () => {
    tracking("comments-click-login-signup", "Dashboard Beta");
    authRedirect();
  };

  const onTextAreaClick = () => {
    if (!hasTrackedInteraction) {
      tracking("comments-click-input-field", "Dashboard Beta");
      setHasTrackedInteraction(true);
    }
  };

  return (
    <Drawer open={isOpen} onOpenChange={(isOpen) => !isOpen && trackedClose()}>
      <DrawerContent hideOverlay={true} className="border border-gray-50 max-sm:right-1 max-sm:left-auto">
        <div className="flex flex-col h-full">
          <DrawerTitle className="mb-2">Comments</DrawerTitle>
          {comments.length === 0 ? (
            <EmptyComments />
          ) : (
            <div ref={containerRef} className="flex-grow overflow-y-auto">
              {comments.map((comment) => {
                return <Comment key={comment.createdAt.toString()} {...comment} />;
              })}
            </div>
          )}
          {isLoggedIn ? (
            <div className="flex flex-col items-end">
              <Textarea
                placeholder="Add a comment..."
                className="my-3"
                onChange={handleInputChange}
                onClick={onTextAreaClick}
                value={newComment}
              />
              <div className="flex justify-between w-full">
                <span
                  className={clsx(
                    "text-xs ml-3",
                    newComment.length === maxContentLength ? "text-red-500 font-bold" : "text-gray-500",
                  )}
                >
                  {`${newComment.length} / ${maxContentLength}`}
                </span>
                <Button disabled={newComment.length < minContentLength} onClick={addComment}>
                  Add Comment
                </Button>
              </div>
            </div>
          ) : (
            <div className="flex justify-center mt-6">
              <Button onClick={onLoginClick}>Login to Comment</Button>
            </div>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};

type CommentsPanelToggleButtonProps = {
  className?: string;
  onClick: () => void;
  resourceId: string;
  resourceType: CommentType["resourceType"];
};

export const CommentsPanelToggleButton = ({
  className,
  onClick,
  resourceId,
  resourceType,
}: CommentsPanelToggleButtonProps) => {
  const { count } = useComments(resourceType, resourceId, { countOnly: true });

  const trackedClick = () => {
    tracking("dashboard-click-comments", "Dashboard Beta", { dashboard_id: resourceId });
    onClick();
  };

  return (
    <Button onClick={trackedClick} variant="secondary" size="sm" className={clsx("flex-1 py-4 h-9 px-4", className)}>
      <MessageCircleIcon className="size-4 text-muted-foreground" />
      <Text className="text-dashboard-text">{`Comments${count ? ` (${count})` : ""}`}</Text>
    </Button>
  );
};
