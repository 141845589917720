import React, { ReactNode } from "react";
import { Button, ButtonProps } from "../button/button";
import clsx from "clsx";

const ButtonGroup = ({ children }: { children: ReactNode }) => {
  const buttons = React.Children.toArray(children);

  return (
    <span className="flex w-full">
      {buttons.map((button, index) => (
        <React.Fragment key={index}>
          {React.cloneElement(button as React.ReactElement<StyledButtonProps>, { index, length: buttons.length })}
        </React.Fragment>
      ))}
    </span>
  );
};

interface StyledButtonProps extends ButtonProps {
  children: ReactNode;
  index?: number;
  length?: number;
  onClick?: () => void;
}

const StyledButton = ({
  children,
  index = 0,
  length = 0,
  onClick,
  disabled,
  variant = "grouped",
}: StyledButtonProps) => {
  const isLeftmost = index === 0;
  const isRightmost = index === length - 1;
  const isMiddle = !isLeftmost && !isRightmost;
  return (
    <Button
      disabled={!!disabled}
      onClick={onClick}
      variant={variant}
      className={clsx(
        {
          "rounded-l-md ": isLeftmost,
          "rounded-l-none": index !== 0,
          "rounded-r-md": isRightmost,
          "rounded-r-none": index !== length - 1,
          "border-l ": isLeftmost || isMiddle,
          "isRightmost border ": isRightmost,
          //   "border-r-2 border-green-50": !isRightmost && isMiddle,
        },
        "background-transparent w-full border-y shadow-sm",
      )}
    >
      {children}
    </Button>
  );
};

ButtonGroup.Root = ButtonGroup;
ButtonGroup.Button = StyledButton;

export { ButtonGroup };
