import { FileUpload } from "@fscrypto/domain/file-upload";
import { Client, HttpClient } from "@fscrypto/http";
import { $path } from "remix-routes";

export class FileUploaderClient {
  constructor(private client: HttpClient) {}

  async upload(file: File): Promise<{ file: FileUpload }> {
    const url = window.location.protocol + "//" + window.location.host + $path("/api/file-upload");
    const formData = new FormData();
    formData.append("file", file);
    return fetch(url, {
      method: "post",
      body: formData,
    }).then((response) => response.json());
  }
}

export const fileUploaderClient = new FileUploaderClient(new Client());
