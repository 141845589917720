import { DashboardView } from "@fscrypto/domain/dashboard";
import { dashboard as dashboardDomain } from "@fscrypto/domain/dashboard";
import {} from "@fscrypto/state-management";
import { useCallback } from "react";
import { useDashboardCellContent } from "../useDashboardCellContent";
import { useEphemeralParamData } from "./useEphemeralParamData";
import { useMergedParamsFromChildQueries } from "./useMergedParametersFromChildQueries";

interface UseParamCellConfigProps {
  dashboardId: string;
  paramCellId: string;
  dashboardView: DashboardView;
}

export const useParamCell = ({ dashboardId, paramCellId, dashboardView }: UseParamCellConfigProps) => {
  // merge parameters from sibling and child queries
  const { mergedParameters, childQueryIds } = useMergedParamsFromChildQueries({
    dashboardId,
    paramCellId,
    dashboardView,
  });

  // This hook uses the params cell content to get the paramIdOrder and hiddenParams
  const [{ paramIdOrder, hiddenParams, id }, setCellContent] = useDashboardCellContent(
    paramCellId,
    dashboardId,
    "params",
    dashboardView,
  );

  // Ephemeral data for the param cell
  const { ephemeralValues, setEphemeralValues, setNumberOfRuns, numberOfRuns } = useEphemeralParamData({
    paramCellId,
    dashboardId,
  });

  // Memoized function to toggle the hidden state of a parameter
  const toggleHidden = useCallback(
    (paramId: string) => {
      const newHiddenParams = dashboardDomain.toggleHiddenParams(paramId, hiddenParams);
      setCellContent({ hiddenParams: newHiddenParams });
    },
    [hiddenParams, setCellContent],
  );

  const orderedParameters = dashboardDomain.reorderParameters(mergedParameters, paramIdOrder);

  const visibleParameters = orderedParameters.filter((p) => !hiddenParams?.includes(p.id));

  const visibleParametersWithEphemeralValues = visibleParameters.reduce<typeof visibleParameters>((acc, p) => {
    const ephemeralValue = ephemeralValues?.[p.name];
    if (ephemeralValue) {
      acc.push({ ...p, value: ephemeralValue });
    }
    return acc;
  }, []);

  return {
    id,
    orderedParameters,
    visibleParameters,
    setParamIdOrder: (paramIdOrder: string[]) => setCellContent({ paramIdOrder }),
    hiddenParams: hiddenParams ?? [],
    toggleHidden,
    ephemeralValues,
    setEphemeralValues,
    childQueryIds,
    visibleParametersWithEphemeralValues,
    numberOfRuns,
    setNumberOfRuns,
    isAdded: !!id,
  };
};
