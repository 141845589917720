import { Button, Card, ScrollArea, Text, Tooltip } from "@fscrypto/ui";
import { Tabs, TabsList, TabsTrigger } from "@fscrypto/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import { MousePointerClick, PaletteIcon, PanelRightClose, PanelRightOpen, SettingsIcon } from "lucide-react";

import { ReactNode } from "react";
import { tracking } from "~/utils/tracking";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { CellConfigRenderer, CellStylesRenderer } from "../cells/cell-renderer";

const Root = ({ children, dashboardId }: { children: ReactNode; dashboardId: string }) => {
  const { showSettingsPanel, openSettingsPanel } = useDashboardEditor({ dashboardId });
  if (!showSettingsPanel)
    return (
      <div className="bg-muted relative h-full py-2">
        <Tooltip content="Open Settings Panel" side="right">
          <Button iconOnly variant="ghost" onClick={openSettingsPanel} className="z-10" size="sm">
            <PanelRightOpen className="size-4 text-muted-foreground flex-shrink-0" />
          </Button>
        </Tooltip>
      </div>
    );
  return <div className="bg-muted flex h-full max-h-full w-[360px] flex-col overflow-hidden">{children}</div>;
};

const ConfigTabs = ({
  settings,
  styles,
  dashboardId,
}: {
  settings: ReactNode;
  styles: ReactNode;
  dashboardId: string;
}) => {
  const { activeConfigPanel, setActiveConfigPanel, closeSettingsPanel } = useDashboardEditor({ dashboardId });
  return (
    <Tabs className="flex h-full flex-col" value={activeConfigPanel ?? "settings"}>
      <TabsList className="w-full rounded-none" size="md">
        <TabsTrigger
          value="settings"
          size="md"
          className="w-full"
          onClick={() => {
            setActiveConfigPanel("settings");
            tracking("dashboard_editor_settings", "Dashboard Beta");
          }}
        >
          <SettingsIcon className="size-4 text-muted-foreground mr-2" />
          <Text>Settings</Text>
        </TabsTrigger>
        <TabsTrigger
          value="styles"
          size="md"
          className="w-full"
          onClick={() => {
            setActiveConfigPanel("styles");
            tracking("dashboard_editor_styles", "Dashboard Beta");
          }}
        >
          <PaletteIcon className="size-4 text-muted-foreground mr-2" />
          <Text>Styles</Text>
        </TabsTrigger>
        <Tooltip content="Close Widget Panel" side="right">
          <Button iconOnly variant="ghost" size="sm" onClick={closeSettingsPanel}>
            <PanelRightClose className="size-4 flex-shrink-0" />
          </Button>
        </Tooltip>
      </TabsList>
      <TabsContent value="settings" className="h-full max-h-full flex-1 overflow-hidden px-2 pb-2">
        {settings}
      </TabsContent>
      <TabsContent value="styles" className="h-full max-h-full flex-1 overflow-hidden px-2 pb-2">
        {styles}
      </TabsContent>
    </Tabs>
  );
};

const SettingsPanel = ({ dashboardId }: { dashboardId: string }) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  return (
    <Card className="flex h-full max-h-full w-full flex-1 flex-col">
      {activeCellId ? (
        <ScrollArea className="h-full w-full max-w-full">
          <CellConfigRenderer cellId={activeCellId ?? undefined} dashboardId={dashboardId} key={activeCellId} />
        </ScrollArea>
      ) : (
        <EmptySettingsPanel />
      )}
    </Card>
  );
};

export const EmptySettingsPanel = () => {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <Text size="sm" className="text-muted-foreground">
        Select an Element to Configure its options{" "}
      </Text>
      <MousePointerClick className="text-muted-foreground size-12 mt-4" />
    </div>
  );
};

export const EmptyStylesPanel = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <Text size="sm" className="text-muted-foreground">
        Select an Element to Configure its options{" "}
      </Text>
      <MousePointerClick className="text-muted-foreground size-12 mt-4" />
    </div>
  );
};

const StylesPanel = ({ dashboardId }: { dashboardId: string }) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  return (
    <Card className="flex h-full max-h-full w-full flex-1 flex-col">
      {activeCellId ? (
        <ScrollArea className="h-full w-full">
          <CellStylesRenderer cellId={activeCellId ?? undefined} dashboardId={dashboardId} key={activeCellId} />
        </ScrollArea>
      ) : (
        <EmptyStylesPanel />
      )}
    </Card>
  );
};

export const DashboardConfig = {
  Root,
  Tabs: ConfigTabs,
  SettingsPanel,
  StylesPanel,
};
