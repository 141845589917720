import { ConfigTitleAndClose } from "../../config-elements/config-elements";
import {
  BackgroundStyles,
  BorderStyles,
  DescriptionColorStyles,
  ShadowStyles,
  StylesAccordion,
  TitleColorStyles,
} from "../../style-elements/style-elements";

export const HeadingCellStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col p-3">
      <ConfigTitleAndClose title="Heading Panel styles" dashboardId={dashboardId} />
      <StylesAccordion>
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} />
        <TitleColorStyles cellId={cellId} dashboardId={dashboardId} />
        <DescriptionColorStyles cellId={cellId} dashboardId={dashboardId} />
        <BorderStyles cellId={cellId} dashboardId={dashboardId} />
        <ShadowStyles cellId={cellId} dashboardId={dashboardId} />
      </StylesAccordion>
    </div>
  );
};
