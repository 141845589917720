import { Entity, EntityFactory, Store, createStore, useEntity, useOptionalStore } from "@fscrypto/state-management";
import { fileUploaderClient, FileUploaderClient } from "../data/file-uploader-client";
import { filter, map } from "rxjs";
import { useEffect } from "react";

type FileData = { url: string | undefined; id: string; uploading?: boolean; file?: File };

class FileUploadEntity implements Entity<FileData> {
  public readonly id: string;
  store: Store<FileData>;

  constructor(
    private client: FileUploaderClient,
    initialValue: { url: FileData["url"]; id: string },
  ) {
    this.id = initialValue.id;
    this.store = createStore({ url: initialValue.url, id: initialValue.id });
  }

  async upload(file: File, onUpload?: (url: string, id: string) => void) {
    this.store.set({ ...this.store.get(), uploading: true, file });
    try {
      const data = await this.client.upload(file);
      this.store.set({ ...this.store.get(), url: data.file.url, uploading: false });
      if (onUpload) {
        onUpload(data.file.url, data.file.id);
      }
    } catch (error) {
      console.error("Upload failed:", error);
      this.store.set({ ...this.store.get(), uploading: false });
      throw error; // Re-throw the error if needed or handle it as per your error handling policy
    }
  }

  remove(onRemove?: () => void) {
    this.store.set({ url: undefined, id: this.id, uploading: false, file: undefined });
    if (onRemove) {
      onRemove();
    }
  }

  get() {
    return this.store.get();
  }
}

class FileUploadFactory implements EntityFactory<FileUploadEntity> {
  files: Store<Record<string, FileUploadEntity>> = createStore({});
  constructor(private client: FileUploaderClient) {}

  async create({ url, id }: FileData & { id: string }) {
    const d = new FileUploadEntity(this.client, { url, id });
    this.files.set({ ...this.files.get(), [d.id]: d });
    return d;
  }
  async getById(id: string) {
    if (this.files.get()[id]) {
      return this.files.get()[id];
    }
    // const dashboard = await this.client.get(id);
    const d = new FileUploadEntity(this.client, { url: undefined, id });
    this.files.set({ ...this.files.get(), [d.id]: d });
    return d;
  }

  from({ url, id }: FileData) {
    const d = new FileUploadEntity(this.client, { url, id });
    this.files.set({ ...this.files.get(), [d.id]: d });
    return d;
  }

  from$(id: string) {
    return this.files.value$.pipe(
      map((v) => v[id]),
      filter(Boolean),
    );
  }
}

export const fileUploadFactory = new FileUploadFactory(fileUploaderClient);

export const useFileUpload = (
  id: string,
  initialUrl?: string,
  onUpload?: (url: string, id: string) => void,
  onRemove?: () => void,
) => {
  const entity = useEntity(fileUploadFactory, id);
  useEffect(() => {
    fileUploadFactory.from({ url: initialUrl, id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUrl]);
  const value = useOptionalStore(entity?.store);
  if (!value || !entity) {
    return {
      isUploading: false,
    };
  }
  const url = value.url ? value.url : value.file ? URL.createObjectURL(value.file) : undefined;
  return {
    url,
    uploadFile: (file: File) => entity.upload(file, onUpload),
    isUploading: value.uploading ?? false,
    removeFile: () => entity.remove(onRemove),
  };
};
