import { Store } from "@fscrypto/state-management";
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, fromEvent, map, merge } from "rxjs";

/**
 * MouseEventStore class implements the Store interface for managing the active layout ID.
 * It provides methods to initialize, get, and set the active layout ID based on mouse events.
 * The active layout id is used to determine which cell is currently being hovered over.
 * This is important for the hovering mask and the ancestor border.
 */
class MouseEventStore implements Store<string | null> {
  /** BehaviorSubject to store and emit the current active layout ID */
  private activeLayoutId$: BehaviorSubject<string | null>;
  private isDragging$: BehaviorSubject<boolean>;

  constructor() {
    this.activeLayoutId$ = new BehaviorSubject<string | null>(null);
    this.isDragging$ = new BehaviorSubject<boolean>(false);
  }

  /**
   * Initializes the mouse event listener on the provided root element.
   * @param rootElement - The HTML element to attach the mouse event listener to.
   */
  initialize(rootElement: HTMLElement) {
    const mouseOver$ = createMouseOverObservable(rootElement, this.isDragging$);
    mouseOver$.subscribe((cellId) => {
      this.activeLayoutId$.next(cellId);
    });
  }

  /**
   * Returns an Observable that emits the current active layout ID.
   * @returns An Observable of string | null representing the active layout ID.
   */
  get value$(): Observable<string | null> {
    return this.activeLayoutId$.asObservable();
  }

  /**
   * Returns the current value of the active layout ID.
   * @returns The current active layout ID as string | null.
   */
  get(): string | null {
    return this.activeLayoutId$.value;
  }

  /**
   * Sets a new value for the active layout ID.
   * @param value - The new active layout ID to set.
   */
  set(value: string | null) {
    this.activeLayoutId$.next(value);
  }

  /**
   * Sets the dragging state.
   * @param isDragging - Whether the mouse is being dragged.
   */
  setDragging(isDragging: boolean) {
    this.isDragging$.next(isDragging);
  }
}

/** Singleton instance of MouseEventStore */
export const mouseEventStore = new MouseEventStore();

/**
 * Creates an Observable that emits cell IDs based on mouse movement over elements with data-cell-id attribute.
 * @param rootElement - The HTML element to attach the mouse event listener to.
 * @param isDragging$ - BehaviorSubject to track whether the mouse is being dragged.
 * @returns An Observable that emits string | null representing cell IDs.
 */
function createMouseOverObservable(
  rootElement: HTMLElement,
  isDragging$: BehaviorSubject<boolean>,
): Observable<string | null> {
  return merge(
    fromEvent<MouseEvent>(rootElement, "mousemove"),
    fromEvent<MouseEvent>(rootElement, "mousedown"),
    fromEvent<MouseEvent>(document, "mouseup"),
  ).pipe(
    map((event: MouseEvent) => {
      // Update dragging state based on mousedown and mouseup events
      if (event.type === "mousedown") {
        isDragging$.next(true);
      } else if (event.type === "mouseup") {
        isDragging$.next(false);
      }

      // If currently dragging, return "dragging" instead of a cell ID
      if (isDragging$.value) {
        return "dragging";
      }

      // Get the event path (chain of elements from target to root)
      const path = event.composedPath();
      // Iterate through the path to find the first element with a cellId
      for (const element of path) {
        if (element instanceof HTMLElement && element.dataset.cellId) {
          return element.dataset.cellId;
        }
      }
      // If no element with cellId is found, return null
      return null;
    }),
    debounceTime(10),
    // Only emit when the cell ID changes
    distinctUntilChanged(),
  );
}
