import { useEffect } from "react";

import { DashboardView } from "@fscrypto/domain/dashboard";
import { useQuery } from "../../../query/state/query";
import { visualizationFactory } from "../../../visualization/state/visualization";
import { dashboardDataManager } from "../../state/dashboard-data";
import { useClosestParamCell } from "../param-cells/useClosestParamCell";
import { useParamCell } from "../param-cells/useParamCell";
import { useDashboard } from "../useDashboard";
import { useDashboardCellContent } from "../useDashboardCellContent";
import { useDashboardEditor } from "../useDashboardEditor";

export const useVisualizationCell = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const [{ visId, queryId }] = useDashboardCellContent(cellId, dashboardId, "visualization", dashboardView);
  const queryEntity = useQuery(queryId!);
  const { addDashboardCell } = useDashboard(dashboardId);
  const { activeLayoutId } = useDashboardEditor({ dashboardId });

  // Get the closest param cell
  const closestParamCellId = useClosestParamCell(dashboardId, cellId, dashboardView);

  // Get the param cell data if a closest param cell exists
  const { visibleParameters, isAdded } = useParamCell({
    dashboardId,
    paramCellId: closestParamCellId ?? "",
    dashboardView,
  });

  useEffect(() => {
    if (!queryId || !visId) return;
    dashboardDataManager.getQueryData(queryId);
    dashboardDataManager.getVisData(visId);
  }, [queryId, visId]);

  const handleCloneViz = async () => {
    if (!queryId || !visId) return;
    const viz = await visualizationFactory.clone(visId);
    addDashboardCell?.({
      variant: "visualization",
      parentId: activeLayoutId ?? "root",
      content: {
        visId: viz.id,
        queryId: queryId ?? undefined,
      },
    });
  };

  if (!visId || !queryId) {
    return { canRender: false as const };
  }
  const queryParams = queryEntity?.query?.parameters;
  const filteredQueryParams = visibleParameters?.filter(
    (param) => queryParams && queryParams.some((qParam) => qParam.name === param.name),
  );

  return {
    canRender: true as const,
    visId,
    queryId,
    query: queryEntity,
    cloneViz: handleCloneViz,
    runQuery: queryEntity?.run,
    filteredQueryParams,
    layoutHasParamCells: isAdded,
  };
};
