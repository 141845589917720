import { ConfigTitleAndClose, CustomIcon, TitleDescription } from "../../config-elements/config-elements";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { ImageInput } from "../../inputs/image-input";
import { SliderField } from "../../inputs/slider-field";
import { TextInputField } from "../../inputs/text-field";
import { ImageCellContent } from "@fscrypto/domain/dashboard";
import { SelectField } from "../../inputs/select-field";

export const ImageCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [cellContent, setCellContent] = useDashboardCellContent(cellId, dashboardId, "image", "draft");
  return (
    <div className="mb-4 flex h-full w-full flex-col gap-y-4 p-3">
      <ConfigTitleAndClose title="Image Panel" dashboardId={dashboardId} />
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
      {cellContent && (
        <ImageInput
          id={cellId}
          url={cellContent?.imageUrl}
          onUpload={(url) => setCellContent({ imageUrl: url })}
          onRemove={() => setCellContent({ imageUrl: undefined })}
          showLabel
        />
      )}
      <SliderField label="Opacity" value={cellContent.opacity} onChange={(opacity) => setCellContent({ opacity })} />

      <TextInputField
        value={cellContent?.altText ?? ""}
        onChange={(altText) => setCellContent({ altText })}
        label="Alt Text"
      />
      <TextInputField
        value={cellContent?.link ?? ""}
        onChange={(link) => setCellContent({ link })}
        label={"Link URL"}
        startAddon="https://"
      />
      <SelectField
        value={cellContent?.fit ?? "contain"}
        onChange={(fit) => setCellContent({ fit: fit as ImageCellContent["fit"] })}
        label="Object Fit"
        options={[
          { label: "Contain", value: "contain" },
          { label: "Cover", value: "cover" },
          { label: "Fill", value: "fill" },
          { label: "Scale Down", value: "scale-down" },
        ]}
      />
    </div>
  );
};
