import { queryRun as queryRunDomain } from "@fscrypto/domain";
import { DashboardView } from "@fscrypto/domain/dashboard";
/**
 * Custom hook to fetch and process query run results.
 * @param {UseQueryParamsProps} props - The properties required for the hook.
 * @returns {Object} An object containing the query run result, running status, canceled status, and closest parameter cell ID.
 */
import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { queryFactory } from "../../../query/state/query";
import { useClosestParamCell } from "../param-cells/useClosestParamCell";
import { useParamCell } from "../param-cells/useParamCell";

interface UseQueryParamsProps {
  queryId: string;
  dashboardId: string;
  cellId: string;
  dashboardView: DashboardView;
}

/**
 * Custom hook to fetch and process query run results.
 * @param {UseQueryParamsProps} props - The properties required for the hook.
 * @returns {Object} An object containing the query run result, running status, canceled status, and closest parameter cell ID.
 */
export const useQueryRunResultFromCell = ({ queryId, dashboardId, cellId, dashboardView }: UseQueryParamsProps) => {
  // Fetch the query entity
  const entity = useEntity(queryFactory, queryId);

  // Get the closest parameter cell ID
  const closestParamCellId = useClosestParamCell(dashboardId, cellId, dashboardView);
  const { numberOfRuns } = useParamCell({
    dashboardId,
    paramCellId: closestParamCellId!,
    dashboardView,
  });

  // Determine which run store to use (parameter run or latest run)
  // A parameter run is a run that was triggered by a parameter cell and will not be present until the parameter run is triggered
  const paramRunStore = closestParamCellId ? entity?.parameterRuns.get()[closestParamCellId] : undefined;
  const runStore = paramRunStore ? paramRunStore : entity?.latestRun;

  // Get the run from the store
  const run = useStoreWithDynamicSelector(runStore, (s) => s);

  // Determine if the query is running or canceled
  const isRunning = run ? queryRunDomain.isRunning(run?.status) : false;
  const isCanceled = run ? queryRunDomain.isCanceled(run?.status) : false;
  return {
    run,
    isRunning,
    isCanceled,
    closestParamCellId,
    status: run?.status,
    numberOfRuns,
  };
};
