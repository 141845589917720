import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { dashboardFactory } from "../state/dashboard";
import { CellLayout, DashboardView, dashboard } from "@fscrypto/domain/dashboard";

export const useLayout = (cellId: string, dashboardId: string, dashboardView: DashboardView) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const val = useOptionalStore(db?.store, (s) => {
    return dashboard.getLayoutsFromCellId(s, cellId, dashboardView);
  });
  return (val ?? []) as CellLayout[];
};
