import { ConfigTitleAndClose } from "../../config-elements/config-elements";
import { BackgroundStyles, BorderStyles, ShadowStyles, StylesAccordion } from "../../style-elements/style-elements";

export const HeaderCellStyles = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col p-3">
      <ConfigTitleAndClose title="Header styles" dashboardId={dashboardId} />
      <StylesAccordion>
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} />
        <BorderStyles cellId={cellId} dashboardId={dashboardId} />
        <ShadowStyles cellId={cellId} dashboardId={dashboardId} />
      </StylesAccordion>
    </div>
  );
};
