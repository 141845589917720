import React from "react";
import { ConfigTitleAndClose } from "../../config-elements/config-elements";
import {
  BackgroundStyles,
  BorderStyles,
  ShadowStyles,
  StylesAccordion,
  TitleDescriptionAlignStyles,
  TitleSizeStyles,
} from "../../style-elements/style-elements";

interface ParamsCellStylesProps {
  cellId: string;
  dashboardId: string;
}

export const ParamsCellStyles: React.FC<ParamsCellStylesProps> = ({ cellId, dashboardId }) => {
  return (
    <div className="flex h-full w-full flex-col p-3">
      <ConfigTitleAndClose title="Parameters Panel Styles" dashboardId={dashboardId} />
      <StylesAccordion>
        <TitleSizeStyles cellId={cellId} dashboardId={dashboardId} />
        <TitleDescriptionAlignStyles cellId={cellId} dashboardId={dashboardId} />
        <BackgroundStyles cellId={cellId} dashboardId={dashboardId} />
        <BorderStyles cellId={cellId} dashboardId={dashboardId} />
        <ShadowStyles cellId={cellId} dashboardId={dashboardId} />
      </StylesAccordion>
    </div>
  );
};
