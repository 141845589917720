// import { ColorPicker } from "@fscrypto/ui";

import { Text } from "@fscrypto/ui";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@fscrypto/ui/tabs";
import clsx from "clsx";
import { BanIcon, ImageIcon } from "lucide-react";
import { ColorPicker } from "@fscrypto/ui";
import { BaseCellStyles } from "@fscrypto/domain/dashboard";
import { BackgroundStyleType } from "node_modules/@fscrypto/domain/src/dashboard/configSchema";
import { ImageInput } from "./image-input";

export const BackgroundField = ({
  background,
  onChange,
}: {
  background: BaseCellStyles["background"];
  onChange: (bg: BaseCellStyles["background"]) => void;
}) => {
  return (
    <div>
      <Tabs
        value={background?.type ?? "solid"}
        className="flex h-full flex-col"
        onValueChange={(val) => onChange({ ...background, type: val as BackgroundStyleType })}
      >
        <TabsList className="grid w-full grid-cols-5" size="sm">
          <TabsTrigger value="solid" size="sm" className="w-full px-1">
            <SolidIcon />
          </TabsTrigger>
          <TabsTrigger value="gr-vert" size="sm" className="w-full px-1">
            <VertGrdIcon />
          </TabsTrigger>
          <TabsTrigger value="gr-horiz" size="sm" className="w-full px-1">
            <HorizGrdIcon />
          </TabsTrigger>
          <TabsTrigger value="gr-radial" size="sm" className="w-full px-1">
            <RadialGrdIcon />
          </TabsTrigger>
          <TabsTrigger value="image" size="sm" className="w-full px-1">
            <ImgIcon />
          </TabsTrigger>
        </TabsList>
        <TabsContent value="solid" className="flex-1 px-2 pb-2">
          <SolidColors background={background} onChange={onChange} />
        </TabsContent>
        <TabsContent value="gr-horiz" className="flex-1 px-2 pb-2">
          <GradientColors
            background={background}
            onChange={onChange}
            gradientType="gr-horiz"
            label1="Left Color"
            label2="Right Color"
          />
        </TabsContent>
        <TabsContent value="gr-vert" className="flex-1 px-2 pb-2">
          <GradientColors
            background={background}
            onChange={onChange}
            gradientType="gr-vert"
            label1="Top Color"
            label2="Bottom Color"
          />
        </TabsContent>
        <TabsContent value="gr-radial" className="flex-1 px-2 pb-2">
          <GradientColors
            background={background}
            onChange={onChange}
            gradientType="gr-radial"
            label1="Center Color"
            label2="Outer Color"
          />
        </TabsContent>
        <TabsContent value="image" className="flex-1 px-2 pb-2">
          <ImageBackground background={background} onChange={onChange} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const SolidIcon = () => {
  return <div className="size-5 bg-primary/50 rounded-full"></div>;
};

const VertGrdIcon = () => {
  return <div className="size-5 from-primary/50 rounded-full bg-gradient-to-b to-white"></div>;
};

const HorizGrdIcon = () => {
  return <div className="size-5 from-primary/50 rounded-full bg-gradient-to-r to-white"></div>;
};

const RadialGrdIcon = () => {
  return <div className="size-5 bg-gradient-radial to-primary rounded-full from-white"></div>;
};
const ImgIcon = () => {
  return (
    <div className="size-5 flex items-center justify-center">
      <ImageIcon className="size-5 text-primary/50" />
    </div>
  );
};

const SolidColors = ({
  background,
  onChange,
}: {
  background: BaseCellStyles["background"];
  onChange: (bg: BaseCellStyles["background"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <AppBackgroundSwatches background={background} onChange={onChange} />
      <ColorPicker
        onChange={(color) => {
          onChange({ ...background, color1: color, appColor: undefined });
        }}
        value={background?.color1 ?? "#fff"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text size="xs" color="muted">
              Custom Color
            </Text>
            <Swatch
              bgColor={color ?? "#fff"}
              isActive={background?.type === "solid" && background.color1 === color && !background.appColor}
            />
          </div>
        )}
      />
    </div>
  );
};

type GradientColorsProps = {
  background: BaseCellStyles["background"];
  onChange: (bg: BaseCellStyles["background"]) => void;
  gradientType: "gr-horiz" | "gr-vert" | "gr-radial";
  label1: string;
  label2: string;
};

const GradientColors = ({ background, onChange, gradientType, label1, label2 }: GradientColorsProps) => {
  const isActive = (color: string, type: "color1" | "color2") =>
    background?.type === "solid" && background[type] === color && !background.appColor;

  return (
    <div className="flex w-full flex-col gap-y-4">
      <ColorPicker
        onChange={(color) => {
          onChange({ ...background, color1: color, appColor: undefined });
        }}
        value={background?.color1 ?? "#fff"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text size="xs" color="muted" className="w-24 text-left">
              {label1}
            </Text>
            <Swatch bgColor={color ?? "#fff"} isActive={isActive(color, "color1")} />
          </div>
        )}
      />
      <ColorPicker
        onChange={(color) => {
          onChange({ ...background, color2: color, appColor: undefined });
        }}
        value={background?.color2 ?? "#fff"}
        trigger={(color) => (
          <div className="flex items-center gap-x-4">
            <Text size="xs" color="muted" className="w-24 text-left">
              {label2}
            </Text>
            <Swatch bgColor={color ?? "#fff"} isActive={isActive(color, "color2")} />
          </div>
        )}
      />
    </div>
  );
};

const AppBackgroundSwatches = ({
  background,
  onChange,
}: {
  background: BaseCellStyles["background"];
  onChange: (bg: BaseCellStyles["background"]) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Text size="xs" color="muted">
        App Colors
      </Text>
      <div className="grid grid-cols-5 gap-2">
        {Object.keys(appBackgroundColors).map((key) => {
          return (
            <AppBgSwatch
              colorKey={key as BGColorKey}
              key={key}
              isActive={background?.type === "solid" && background.appColor === appBackgroundColors[key as BGColorKey]}
              onClick={() => {
                onChange({ ...background, appColor: appBackgroundColors[key as BGColorKey] });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

const AppBgSwatch = ({
  colorKey,
  isActive,
  onClick,
}: {
  colorKey: keyof typeof appBackgroundColors;
  isActive: boolean;
  onClick: (key: keyof typeof appBackgroundColors) => void;
}) => {
  const colorStyle = appBackgroundColors[colorKey];
  return (
    <button
      onClick={() => onClick(colorKey)}
      className={clsx("size-8 flex items-center justify-center rounded-lg border shadow", colorStyle, {
        "border-primary border-2": isActive,
      })}
    >
      {colorKey === "app-transparent" && <BanIcon className="text-muted-foreground size-5" />}
    </button>
  );
};

export const Swatch = ({ bgColor, isActive }: { bgColor: string; isActive: boolean }) => (
  <div
    className={clsx("size-8 rounded-lg border shadow", { "border-primary border-2": isActive })}
    style={{ background: bgColor }}
  ></div>
);

export const appBackgroundColors = {
  "app-bg": "bg-background",
  "app-muted": "bg-muted",
  "app-primary": "bg-primary",
  "app-success": "bg-success",
  "app-warning": "bg-warning",
  "app-transparent": "bg-transparent",
};

type BGColorKey = keyof typeof appBackgroundColors;

const ImageBackground = ({
  background,
  onChange,
}: {
  background: BaseCellStyles["background"];
  onChange: (bg: BaseCellStyles["background"]) => void;
}) => {
  return (
    <div className="flex w-full flex-col gap-y-4">
      <ImageInput
        url={background?.bgUrl}
        onUpload={(url) => onChange({ ...background, bgUrl: url })}
        onRemove={() => onChange({ ...background, bgUrl: undefined })}
        id="bg-image"
      />
    </div>
  );
};
