import { useEntity, useStoreWithDynamicSelector } from "@fscrypto/state-management";
import { useCallback } from "react";
import { dashboardFactory } from "../../state/dashboard";

/**
 * A custom hook for managing ephemeral parameter data in a dashboard.
 * Ephemeral Param data is used to store the values of parameters that a user inputs in a param cell.
 *
 * This hook provides functionality to:
 * - Retrieve ephemeral data for a specific parameter cell
 * - Update ephemeral values for parameters
 * - Update the number of runs for a parameter cell
 *
 * @param {Object} params - The parameters for the hook.
 * @param {string} params.paramCellId - The ID of the parameter cell.
 * @param {string} params.dashboardId - The ID of the dashboard.
 * @returns {Object} An object containing:
 *   - ephemeralData: The current ephemeral data for the parameter cell
 *   - setEphemeralValues: A function to update ephemeral values
 *   - setNumberOfRuns: A function to update the number of runs
 *   - numberOfRuns: The current number of runs
 *   - ephemeralValues: The current ephemeral values for the parameters
 */
export const useEphemeralParamData = ({ paramCellId, dashboardId }: { paramCellId: string; dashboardId: string }) => {
  const db = useEntity(dashboardFactory, dashboardId);
  const ephemeralData = useStoreWithDynamicSelector(db?.ephemeralDataStore, (s) => s[paramCellId]);

  const setEphemeralValues = useCallback(
    (paramName: string, value: string) => {
      db?.updateEphemeralParamValues(paramCellId, paramName, value);
    },
    [db, paramCellId],
  );

  const setNumberOfRuns = useCallback(
    (numberOfRuns: number) => {
      db?.updateEphemeralParamRuns(paramCellId);
    },
    [db, paramCellId],
  );

  const numberOfRuns = ephemeralData?.runs;

  return { ephemeralData, setEphemeralValues, setNumberOfRuns, numberOfRuns, ephemeralValues: ephemeralData?.values };
};
