import { DashboardView } from "@fscrypto/domain/dashboard";
import { ParentSize } from "@fscrypto/viz";
import clsx from "clsx";
import { ClientOnly } from "remix-utils/client-only";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, TitleDescription } from "../../content-elements/content-elements";
import { Markdown } from "./markdown.client";

export const TextCellContent = ({
  dashboardId,
  dashboardView,
  cellId,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const [{ html }] = useDashboardCellContent(cellId, dashboardId, "text", dashboardView);
  const [{ theme = "light" }] = useDashboardCellContent("root", dashboardId, "root", dashboardView);
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
      <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
        <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <div className={clsx("flex w-full h-full flex-col")}>
            <div>
              <TitleDescription cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
            </div>
            <div className="flex-1 overflow-hidden">
              <ParentSize debounceTime={100}>
                {({ height }) => {
                  return <ClientOnly>{() => <Markdown height={height} value={html ?? ""} theme={theme} />}</ClientOnly>;
                }}
              </ParentSize>
            </div>
          </div>
        </BackgroundContainer>
      </BorderContainer>
    </ActiveCellListener>
  );
};
