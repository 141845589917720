import { DashboardView } from "@fscrypto/domain/dashboard";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { GridLayout } from "../../editor/dashboard-grid";

export const TabLayoutCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView);
  const { activeLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} hideControls>
      <GridLayout
        dashboardId={dashboardId}
        dashboardView={dashboardView}
        layoutId={cellId}
        layout={layout}
        setLayout={(layout) => {
          updateLayouts?.(layout);
        }}
        draggable={activeLayoutId === cellId && dashboardView === "draft"}
      />
    </ActiveCellListener>
  );
};
