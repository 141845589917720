import { useStore } from "@fscrypto/state-management";
import { mouseEventStore } from "../state/active-hover";
import { ReactNode, useEffect, useRef } from "react";

export const useActiveHoverId = () => {
  return useStore(mouseEventStore);
};

export const ActiveCellListenerProvider = ({ children }: { children: ReactNode }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (rootRef.current) {
      mouseEventStore.initialize(rootRef.current);
    }
  }, []);

  return (
    <div ref={rootRef} className="root-component size-full overflow-auto">
      {children}
    </div>
  );
};
