import { ParentSize } from "@fscrypto/viz";

export const DashboardGridContainer = ({ children }: { children: (width: number) => JSX.Element }) => {
  return (
    <ParentSize>
      {({ width }) => {
        return <div className="size-full flex-1">{children(width)}</div>;
      }}
    </ParentSize>
  );
};
