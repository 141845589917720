import { Field, Label, Slider } from "@fscrypto/ui";

export const SliderField = ({
  value,
  onChange,
  label,
}: {
  value?: number;
  onChange: (value: number) => void;
  label: string;
}) => {
  return (
    <Field>
      <div className="flex w-full items-center justify-between">
        <Label className="text-muted-foreground">{label}</Label>
      </div>
      <Slider defaultValue={[value ?? 100]} onValueChange={([vl]) => onChange(vl)} className="py-1" />
    </Field>
  );
};
