import { useLocation } from "@remix-run/react";

export const useEmbedded = () => {
  const { pathname } = useLocation();
  const isEmbedded = pathname.startsWith("/embeds/");
  return {
    isEmbedded,
    linkTarget: isEmbedded ? "_blank" : "_self",
  };
};
