import { CellInfo, CellVariant } from "@fscrypto/domain/dashboard";
import { Card, cn } from "@fscrypto/ui";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@fscrypto/ui/accordion";
import {
  BoldIcon,
  ChevronRight,
  Code2Icon,
  Columns3Icon,
  Heading2Icon,
  HelpCircle,
  HomeIcon,
  ImageIcon,
  LayoutDashboardIcon,
  LayoutTemplate,
  LucideIcon,
  PanelTopIcon,
} from "lucide-react";
import { useState } from "react";
import { tracking } from "~/utils/tracking";
import { useDashboardCell } from "../../hooks/useDashboardCell";
import { useDashboardCellContent } from "../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../hooks/useDashboardEditor";
import { mouseEventStore } from "../../state/active-hover";

export const DefaultDashboardTree = ({ dashboardId, layoutsOnly }: { dashboardId: string; layoutsOnly?: boolean }) => {
  const { setActiveCellId, setActiveLayoutId } = useDashboardEditor({ dashboardId });
  return (
    <Card className="h-full py-3 pr-2">
      <TreeItem
        cellId={"root"}
        dashboardId={dashboardId}
        layoutsOnly={layoutsOnly}
        onSelectCell={(cell) => {
          const layoutId = determineLayoutToActivate(cell);
          setActiveLayoutId?.(layoutId!);
          tracking("dashboard_editor_click_layers", "Dashboard Beta");
          setActiveCellId?.(cell.id);
        }}
      />
    </Card>
  );
};

export const LayoutTree = ({
  dashboardId,
  rowContent,
  onSelectCell,
  currentCellId,
}: {
  dashboardId: string;
  rowContent?: (cell: CellInfo, isHovered: boolean) => React.ReactNode;
  onSelectCell?: (cell: CellInfo) => void;
  currentCellId?: string;
}) => {
  return (
    <Card className="h-full py-3 pr-2">
      <TreeItem
        cellId={"root"}
        dashboardId={dashboardId}
        layoutsOnly={true}
        rowContent={rowContent}
        onSelectCell={onSelectCell}
        currentCellId={currentCellId}
      />
    </Card>
  );
};

const TreeItem = ({
  cellId,
  dashboardId,
  layoutsOnly,
  rowContent,
  onSelectCell,
  currentCellId,
}: {
  cellId: string;
  dashboardId: string;
  layoutsOnly?: boolean;
  onSelectCell?: (cell: CellInfo) => void;
  rowContent?: (cell: CellInfo, isHovered: boolean) => React.ReactNode;
  currentCellId?: string;
}) => {
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  // if the cell in the tree is the current cell for layout movement, dont render it as an option as it can not move to itself or its children.
  if (cellId === currentCellId) {
    return null;
  }
  const isLeafNode = isLeaf(cell?.variant);
  if (isLeafNode) {
    if (layoutsOnly) return null;
    return <Leaf cellId={cellId} dashboardId={dashboardId} rowContent={rowContent} onSelectCell={onSelectCell} />;
  }

  return (
    <Branch
      cellId={cellId}
      dashboardId={dashboardId}
      layoutsOnly={layoutsOnly}
      rowContent={rowContent}
      onSelectCell={onSelectCell}
      currentCellId={currentCellId}
    />
  );
};

const isLeaf = (variant?: CellVariant) => {
  return variant !== "root" && variant !== "layout" && variant !== "tab-layout" && variant !== "tabs-panel";
};

const Leaf = ({
  cellId,
  dashboardId,
  rowContent,
  onSelectCell,
}: {
  cellId: string;
  dashboardId: string;
  rowContent?: (cell: CellInfo, isHovered: boolean) => React.ReactNode;
  onSelectCell?: (cell: CellInfo) => void;
}) => {
  const { activeCellId } = useDashboardEditor({ dashboardId });
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  const isActive = activeCellId === cellId;
  return (
    <div
      className={cn("hover:bg-secondary ml-2 flex cursor-pointer items-center rounded-lg py-1 pr-2", {
        "bg-secondary": isActive,
      })}
      onClick={(e) => {
        e.stopPropagation();
        tracking("dashboard_editor_click_layers", "Dashboard Beta");
        onSelectCell?.(cell);
      }}
    >
      <RowContent cellId={cellId} dashboardId={dashboardId} rowContent={rowContent} onRowSelect={onSelectCell} />
    </div>
  );
};

export const Branch = ({
  cellId,
  dashboardId,
  layoutsOnly,
  onSelectCell,
  rowContent,
  currentCellId,
}: {
  cellId: string;
  dashboardId: string;
  layoutsOnly?: boolean;
  onSelectCell?: (cell: CellInfo) => void;
  rowContent?: (cell: CellInfo, isHovered: boolean) => React.ReactNode;
  currentCellId?: string;
}) => {
  const { cell, toggleOpen } = useDashboardCell(cellId, dashboardId, "draft");
  const { activeCellId } = useDashboardEditor({ dashboardId });
  const isOpen = cell?.isOpen ?? false;
  const isActive = activeCellId === cellId;
  return (
    <Accordion type="single" value={isOpen || layoutsOnly ? cellId : "closed"}>
      <AccordionItem value={cellId} key={cellId} className="">
        <AccordionTrigger
          disabled={cell?.variant === "tabs-panel"}
          caretPosition="left"
          Caret={ChevronRight}
          onClick={() => {
            toggleOpen();
            onSelectCell?.(cell);
            tracking("dashboard_editor_click_layers", "Dashboard Beta");
          }}
          className={cn("hover:bg-secondary ml-2 rounded-lg px-2 py-1", {
            "bg-secondary": isActive,
          })}
        >
          <RowContent cellId={cellId} dashboardId={dashboardId} rowContent={rowContent} onRowSelect={onSelectCell} />
        </AccordionTrigger>
        <AccordionContent className="ml-5 border-l">
          {(cell?.childCellIds ?? []).map((cellId) => (
            <TreeItem
              cellId={cellId}
              key={cellId}
              dashboardId={dashboardId}
              layoutsOnly={layoutsOnly}
              rowContent={rowContent}
              onSelectCell={onSelectCell}
              currentCellId={currentCellId}
            />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const RowContent = ({
  cellId,
  dashboardId,
  onRowSelect,
  rowContent,
}: {
  cellId: string;
  dashboardId: string;
  onRowSelect?: (cell: CellInfo, layoutId?: string | null) => void;
  rowContent?: (cell: CellInfo, isHovered: boolean) => React.ReactNode;
}) => {
  const [{ title, variant }] = useDashboardCellContent(cellId, dashboardId, "base", "draft");
  const { cell } = useDashboardCell(cellId, dashboardId, "draft");
  const Icon = variantIconMap[variant ?? "root"];
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div
      className="flex h-7 w-full items-center justify-between"
      onMouseEnter={() => {
        setIsHovered(true);
        mouseEventStore?.set(cellId);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
        mouseEventStore?.set("outside");
      }}
      onClick={(e) => {
        e.stopPropagation();
        onRowSelect?.(cell);
      }}
    >
      <div className="text-muted-foreground flex items-center text-sm font-medium">
        <Icon className="size-4 text-muted-foreground mx-2" />
        {title ?? cell.id}
      </div>
      {rowContent ? rowContent(cell, isHovered) : null}
    </div>
  );
};

const variantIconMap: Record<CellVariant, LucideIcon> = {
  root: HomeIcon,
  image: ImageIcon,
  layout: LayoutTemplate,
  text: BoldIcon,
  base: HelpCircle,
  header: PanelTopIcon,
  "tabs-panel": Columns3Icon,
  "tab-layout": LayoutDashboardIcon,
  visualization: PanelTopIcon,
  "legacy-table": PanelTopIcon,
  heading: Heading2Icon,
  params: Code2Icon,
};

const determineLayoutToActivate = ({ variant, id, parentCellId, childCellIds }: CellInfo) => {
  if (variant === "root" || variant === "layout" || variant === "tab-layout") {
    return id;
  } else if (variant === "tabs-panel") {
    return childCellIds?.[0];
  }
  return parentCellId;
};
