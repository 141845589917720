import { Text } from "@fscrypto/ui";
import { ConfigTitleAndClose } from "../../config-elements/config-elements";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { DarkModeField } from "../../inputs/dark-mode-field";

export const RootCellConfig = ({ dashboardId }: { dashboardId: string }) => {
  const [cellContent, setCellContent] = useDashboardCellContent("root", dashboardId, "root", "draft");
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <ConfigTitleAndClose title="Root Layout" dashboardId={dashboardId} hideClose />
      <Text size="sm" color={"muted"}>
        This panel controls the root layout panel of the entire Dashboard
      </Text>
      <DarkModeField
        theme={cellContent?.theme}
        setTheme={(theme) => {
          setCellContent({ ...cellContent, theme });
        }}
      />
    </div>
  );
};
