import { DashboardView } from "@fscrypto/domain/dashboard";
import clsx from "clsx";
import { Loader2Icon, Settings2Icon } from "lucide-react";
import { useDashboard } from "../../../hooks/useDashboard";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardEditor } from "../../../hooks/useDashboardEditor";
import { useLayout } from "../../../hooks/useLayout";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { DashboardBackgroundContainer } from "../../content-elements/content-elements";
import { GridLayout } from "../../editor/dashboard-grid";

const cellId = "root";

export const RootCellContent = ({
  dashboardId,
  dashboardView,
}: {
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { updateLayouts } = useDashboard(dashboardId);
  const layout = useLayout(cellId, dashboardId, dashboardView);
  const { activeLayoutId, setActiveCellId } = useDashboardEditor({ dashboardId });
  if (!layout.length)
    return (
      <div className="size-full bg-muted flex justify-center pt-20">
        <Loader2Icon className="size-8 text-muted-foreground animate-spin" />
      </div>
    );
  return (
    <RootCellContainer dashboardId={dashboardId} dashboardView={dashboardView}>
      <div
        className={clsx("relative flex w-full flex-1 flex-col", {
          "pt-6": dashboardView === "draft",
        })}
      >
        <GridLayout
          dashboardView={dashboardView}
          dashboardId={dashboardId}
          layoutId={cellId}
          layout={layout}
          draggable={activeLayoutId === cellId && dashboardView === "draft"}
          setLayout={(layout) => {
            updateLayouts?.(layout);
          }}
        />
        {dashboardView === "draft" && (
          <div
            className="bg-primary absolute right-0 top-0 m-1 flex h-8 cursor-pointer items-center gap-x-2 rounded px-2"
            onClick={() => setActiveCellId(cellId)}
          >
            <Settings2Icon className="size-5 text-primary-foreground" />
          </div>
        )}
      </div>
    </RootCellContainer>
  );
};

const RootCellContainer = ({
  dashboardId,
  children,
  dashboardView,
}: {
  dashboardId: string;
  children: React.ReactNode;
  dashboardView: DashboardView;
}) => {
  const [{ theme }] = useDashboardCellContent("root", dashboardId, "root", dashboardView);
  return (
    <div
      className={clsx("flex w-full flex-1 flex-col border ", {
        light: theme === "light",
        dark: theme === "dark",
        "bg-muted min-h-[6000px] border-2": dashboardView == "draft",
      })}
      data-cell-id={"outside"}
    >
      <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} hideControls>
        <DashboardBackgroundContainer dashboardId={dashboardId} dashboardView={dashboardView}>
          {children}
        </DashboardBackgroundContainer>
      </ActiveCellListener>
    </div>
  );
};
