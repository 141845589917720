import { Dashboard } from "@fscrypto/domain/dashboard";
import { dashboardFactory } from "../state/dashboard";
import { useEntity, useOptionalStore } from "@fscrypto/state-management";
import { eventBus } from "~/state/events";
import { ProfilePublic } from "node_modules/@fscrypto/domain/src/profile/profile";

export const useDashboardSettings = (id: string) => {
  const entity = useEntity(dashboardFactory, id);
  const db = useOptionalStore(entity?.store);
  const owner = useOptionalStore(entity?.ownerStore);
  const likes = useOptionalStore(entity?.likeStore);
  if (!db || !entity || !owner) {
    return {} as const;
  }
  return {
    contributors: entity.contributors,
    coverImageUrl: db.coverImage?.url,
    createCopyUrl: () => createCopyUrl(db, owner),
    dashboardTags: entity.dashboardTags,
    fork: dashboardFactory.fork.bind(dashboardFactory),
    isPublished: db.publishedAt !== null,
    latestSlug: db.latestSlug,
    likes,
    owner,
    publish: entity.publish.bind(entity),
    publishedAt: db.publishedAt,
    questEcosystemProjects: entity.questEcosystemProjects,
    refreshDashboard: entity.refreshDashboard.bind(entity),
    setDescription: entity.updateDescription.bind(entity),
    setTitle: entity.updateTitle.bind(entity),
    shareOnTwitter: () => shareOnTwitter(db, owner),
    title: db.title,
    toggleLikes: entity.toggleLike.bind(entity),
    unpublish: entity.unpublish.bind(entity),
    updateDashboardImageId: entity.updateDashboardImageId.bind(entity),
    updateVisibility: entity.updateVisibility.bind(entity),
    visibility: db.visibility,
  };
};

const createCopyUrl = async (db: Dashboard, owner: ProfilePublic) => {
  const baseUrl = window.ENV.BASE_URL;
  const url = `${baseUrl}/${owner.slug}/${db.latestSlug}`;
  await navigator.clipboard.writeText(url);
  eventBus.send({ type: "TOAST.NOTIFY", notif: { title: "Copied Link to Clipboard", type: "success" } });
};

const shareOnTwitter = (db: Dashboard, owner: ProfilePublic) => {
  const baseUrl = window.ENV.BASE_URL;
  const url = `${baseUrl}/${owner.slug}/${db.latestSlug}`;
  const twitterUrl = `https://twitter.com/share?url=${url}`;
  window.open(twitterUrl, "_blank", "noopener,noreferrer");
};
