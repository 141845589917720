import { Avatar, Button, Tag, Text, TwitterIcon } from "@fscrypto/ui";

import clsx from "clsx";

import { DashboardView } from "@fscrypto/domain/dashboard";
import { Link } from "@remix-run/react";
import { HeartIcon, RefreshCcwIcon } from "lucide-react";
import { DateTime } from "luxon";
import { useState } from "react";
import { $path } from "remix-routes";
import MarketingAuthModal from "~/features/app-shell/marketing/marketing-auth-modal";
import { CommentsPanel, CommentsPanelToggleButton } from "~/features/comments/ui/commentsPanel";
import { useCurrentUser } from "~/features/current-user";
import { useEmbedded } from "~/hooks/useEmbedded";
import { useFeatureFlag } from "~/state/feature-flags";
import { tracking } from "~/utils/tracking";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { useDashboardCellStyles } from "../../../hooks/useDashboardCellStyles";
import { useDashboardSettings } from "../../../hooks/useDashboardSettings";
import { ActiveCellListener } from "../../active-cell/active-cell-listener";
import { BackgroundContainer, BorderContainer, Title } from "../../content-elements/content-elements";
import { QuestLink } from "./quest-link";

export const HeaderCellContent = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const { owner, publishedAt } = useDashboardSettings(dashboardId);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const isCommentsEnabled = useFeatureFlag("dashboard-comments");
  const { linkTarget } = useEmbedded();
  return (
    <>
      <ActiveCellListener cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} hideControls>
        <BorderContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
          <BackgroundContainer cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView}>
            <div className="flex items-center">
              <div className={clsx("flex h-full w-full flex-col")}>
                <HeaderTitle cellId={cellId} dashboardId={dashboardId} dashboardView={dashboardView} />
                <div className="flex items-center gap-x-2 px-2 pb-2 pt-1">
                  <Link to={owner?.profileUrl ?? ""} target={linkTarget} className="flex items-center gap-x-2">
                    <Avatar className="" size={"sm"} src={owner?.avatarUrl ?? ""} />
                    <Text size="sm" weight={"semi"}>
                      {owner?.name}
                    </Text>
                  </Link>
                  <Contributors dashboardId={dashboardId} dashboardView={dashboardView} />
                  {publishedAt && (
                    <>
                      <span className="text-muted-foreground text-sm">|</span>
                      <Text size="xs" weight={"semi"} color="muted">
                        {DateTime.fromJSDate(publishedAt).toFormat("dd LLL yyyy")}
                      </Text>
                    </>
                  )}
                </div>
              </div>
              <div className="flex gap-x-3 p-3">
                <QuestLink dashboardId={dashboardId} dashboardView={dashboardView} />
                {isCommentsEnabled && (
                  <CommentsPanelToggleButton
                    onClick={() => setIsCommentsOpen(true)}
                    resourceId={dashboardId}
                    resourceType="dashboard"
                  />
                )}
                <Likes dashboardId={dashboardId} dashboardView={dashboardView} />
                <Refresh dashboardId={dashboardId} />
                <TwitterShare dashboardId={dashboardId} />
              </div>
            </div>
          </BackgroundContainer>
        </BorderContainer>
      </ActiveCellListener>
      {isCommentsEnabled && (
        <CommentsPanel
          isOpen={isCommentsOpen}
          onClose={() => setIsCommentsOpen(false)}
          resourceId={dashboardId}
          resourceType="dashboard"
        />
      )}
    </>
  );
};

const HeaderTitle = ({
  cellId,
  dashboardId,
  dashboardView,
}: {
  cellId: string;
  dashboardId: string;
  dashboardView: DashboardView;
}) => {
  const [{ dashboardTitle }] = useDashboardCellContent(cellId, dashboardId, "header", dashboardView);
  const [cellStyle] = useDashboardCellStyles(cellId, dashboardId, "header", dashboardView);

  return (
    <div className={clsx("flex flex-col px-2 py-1")}>
      <Title title={dashboardTitle} titleSize={"2xl"} titleColor={cellStyle?.titleColor} />
    </div>
  );
};

const Likes = ({ dashboardId, dashboardView }: { dashboardId: string; dashboardView: DashboardView }) => {
  const { currentUser } = useCurrentUser();
  const { likes, toggleLikes } = useDashboardSettings(dashboardId);
  const likedByMe = likes?.likedByMe ?? false;
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };

  const trackedToggleLikes = () => {
    if (!likedByMe) {
      tracking("dashboard_editor_like", "Dashboard Beta");
    }
    toggleLikes?.();
  };

  return (
    <>
      <Button
        onClick={(e) => {
          if (currentUser) {
            trackedToggleLikes();
          } else {
            setOpenAuthModal(true);
          }
        }}
        variant="secondary"
        disabled={likes?.loading}
      >
        <HeartIcon
          className={clsx("h4 w-4", {
            "fill-red-50 text-red-50": likedByMe,
            "text-muted-foreground": !likedByMe,
          })}
        />
        <Text className="text-dashboard-text">{likes?.likes ?? 0}</Text>
      </Button>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-like"
      />
    </>
  );
};

const Refresh = ({ dashboardId }: { dashboardId: string }) => {
  const { refreshDashboard } = useDashboardSettings(dashboardId);
  const { currentUser } = useCurrentUser();
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const onOpenAuthModalChange = (isOpen: boolean) => {
    if (!isOpen) {
      setOpenAuthModal(false);
    }
  };
  return (
    <>
      <Button
        onClick={(e) => {
          if (currentUser) {
            refreshDashboard?.();
          } else {
            setOpenAuthModal(true);
          }
        }}
        variant="secondary"
      >
        <RefreshCcwIcon className="size-5 text-muted-foreground mr-2" />
        Refresh
      </Button>
      <MarketingAuthModal
        open={openAuthModal}
        redirectBackOnClose={false}
        defaultOpen={false}
        onOpenChange={onOpenAuthModalChange}
        persona="viewer"
        origin="dashboard-refresh"
      />
    </>
  );
};

const Contributors = ({ dashboardId }: { dashboardId: string; dashboardView: DashboardView }) => {
  const { contributors } = useDashboardSettings(dashboardId);
  if ((contributors ?? []).length === 0) return null;
  return (
    <div className="flex">
      {contributors?.map((contributor) => (
        <Link to={$path("/:owner/dashboards", { owner: contributor.username })} key={contributor.id}>
          <Tag>@{contributor.username}</Tag>
        </Link>
      ))}
    </div>
  );
};

const TwitterShare = ({ dashboardId }: { dashboardId: string }) => {
  const { shareOnTwitter } = useDashboardSettings(dashboardId);
  return (
    <Button
      variant="secondary"
      className="flex-1 cursor-pointer"
      asChild
      onClick={shareOnTwitter}
      data-testid="dashboard-twitter-share"
    >
      <div className="text-gray-70">
        <TwitterIcon className="mr-1 h-4 w-4 fill-[#1DA1F2] text-[#1DA1F2]" />
        Share
      </div>
    </Button>
  );
};
