import { Text } from "@fscrypto/ui";
import { useDashboardCellContent } from "../../../hooks/useDashboardCellContent";
import { ConfigTitleAndClose, CustomIcon, TitleDescription } from "../../config-elements/config-elements";
import { TitleSize, VerticalAlignButtonGroup } from "../../style-elements/style-elements";

export const HeadingCellOptions = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  return (
    <div className="flex h-full w-full flex-col gap-y-4 p-3 ">
      <ConfigTitleAndClose title="Heading Panel" dashboardId={dashboardId} />
      <Text size="sm" color={"muted"}>
        This panel controls the configuration of the header panel. There is only one of these, and is created by default
      </Text>
      <TitleDescription cellId={cellId} dashboardId={dashboardId} />
      <HeadingTitleSize cellId={cellId} dashboardId={dashboardId} />
      <HeadingAlignText cellId={cellId} dashboardId={dashboardId} />
      <CustomIcon cellId={cellId} dashboardId={dashboardId} />
    </div>
  );
};

const HeadingTitleSize = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [{ titleSize }, setContent] = useDashboardCellContent(cellId, dashboardId, "heading", "draft");
  return <TitleSize value={titleSize} onChange={(value) => setContent({ titleSize: value })} />;
};

export const HeadingAlignText = ({ cellId, dashboardId }: { cellId: string; dashboardId: string }) => {
  const [{ titleAlign = "start" }, setContent] = useDashboardCellContent(cellId, dashboardId, "heading", "draft");
  return <VerticalAlignButtonGroup value={titleAlign} onChange={(titleAlign) => setContent({ titleAlign })} />;
};
