import MDEditor from "@uiw/react-md-editor";
import { cleanHtml } from "../../inputs/markdown-field";

interface MarkdownProps {
  height?: number;
  toggleEditing?: () => void;
  value: string;
  handleHeightChange?: (height?: number | string) => void;
  theme: string;
}
/** markdown style overrides are in markdown-styles.css */
export const Markdown = ({ height, value, toggleEditing, theme }: MarkdownProps) => {
  const sanitizedValue = cleanHtml(value);

  return (
    <div data-color-mode={theme} className="wmde-markdown overflow-auto">
      <MDEditor
        autoFocus={true}
        onBlur={() => toggleEditing?.()}
        height={height ?? "600px"}
        value={sanitizedValue}
        overflow={true}
        className="h-full w-full"
        hideToolbar={true}
        enableScroll={true}
        toolbarBottom={false}
        visibleDragbar={false}
        onKeyDown={(e) => {
          switch (e.key) {
            case "Escape":
              toggleEditing?.();
              break;
          }
        }}
        preview={"preview"}
      />
    </div>
  );
};
